<template>
  <div class="fragment">
    <ExpressOrderShow
        v-if="EO.data.Order.data.type === 'fedex'"
        :easyOrder="true"
    />

    <OrdersFBMShow
        v-if="EO.data.Order.data.type === 'warehouse' || EO.data.Order.data.type === 'consolidation'"
        :easyOrder="true"
    />
  </div>
</template>

<script>
import ExpressOrderShow from "@/components/modules/ExpressOrdersModule/components/ExpressOrderShow/ExpressOrderShow";
import OrdersFBMShow from "@/components/modules/OrdersFBMModule/components/OrdersFBMShow/OrdersFBMShow";
import {EasyOrder} from "@/components/modules/EasyOrdersModule/models/EasyOrder";
export default {
  name: "EasyOrdersShow",
  components: {
    OrdersFBMShow,
    ExpressOrderShow
  },

  data() {
    return {
      EO: new EasyOrder(),
    }
  },

  mounted() {
    if(this.$route.params.type) {
      this.EO.data.Order.setType(this.$route.params.type)
    }
  },

}
</script>

<style scoped>

</style>