<template>

  <div>
    <div class="order-create__row custom-row" v-if="!isLabel">
      <div class="order-create__col custom-col" v-if="!(isUserHasEasyOrder && isConsolidation)"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_ChangeCarrier'])"></div>
        <DefaultCheckbox
            v-bind:class="{'label-red' : isAdmin}"
            class="bold big-label"
            :label="$t('fbm_ChangeCarrier.localization_value.value')"
            :value="FBM.data.changeCarrier"
            :disabled="isAdmin"
            @input="FBM.data.changeCarrier = !FBM.data.changeCarrier"
        />
      </div>
    </div>

    <div class="order-create__row custom-row shipping-block"
         v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBABtn}"
    >
      <div class="order-create__col custom-col">
        <div class="order-create__toggle-block toggle-block small-size">

          <div class="toggle-block__type">
            <div class="toggle-block__type-list">

              <ShippingCompany
                  :shippingCompanyData="shippingCompanyData"
                  :FBM="FBM"
                  @changeShippingPartnerType="item => changeShippingPartnerType(item)"
              />

<!--              <div class="toggle-block__type-item mb-3"-->
<!--                   v-if="!isMobileFunc() && !isAdmin && !isLabel && !(isEasyType && isConsolidation)"-->
<!--                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate([-->
<!--                  'fbm_ShippingCalculating'-->
<!--                ])"></div>-->
<!--                <span class="site-link"-->
<!--                      @click="$emit('showShippingCalculatorPopup')"-->
<!--                >-->
<!--                  {{$t('fbm_ShippingCalculating.localization_value.value')}}-->
<!--                </span>-->
<!--              </div>-->
            </div>

            <ShippingAdminBtns
                :FBM="FBM"
                @returnLabel="returnLabel"
            />

            <!--            <div v-if="FBM.data.fileGift"-->
            <!--                 class="mt-3"-->
            <!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
            <!--              <div class="admin-edit" @click="editTranslate(['fbm_GiftMessage'])"></div>-->
            <!--              <router-link :to="$store.getters.GET_PATHS.getPdfFile + '/' + FBM.data.fileGift.uuid" :target="'_blank'">-->
            <!--                <MainButton-->
            <!--                    class="order-create__footer-btn-i secondary"-->
            <!--                    :value="$t('fbm_GiftMessage.localization_value.value')"-->
            <!--                />-->
            <!--              </router-link>-->
            <!--            </div>-->

          </div>

          <template v-if="$store.getters.getOrdersFBMCompanyLoading">
            <div class="toggle-block__details w-100 ml-5">
              <div class="d-flex justify-content-center align-items-center">
                <DotsShadowPreloader/>
              </div>
            </div>
          </template>

          <template v-else-if="!(FBM.data.useEasyPost && _.has(getShippingPartner, 'shipping_methods') && getShippingPartner['shipping_methods'].length === 1) ||
            (this.isCurrentUserRoleUser && FBM.data.useEasyPost && !this.isLabel)">

            <ShippingMethods
                :FBM="FBM"
                :getShippingPartner="getShippingPartner"
                :isAdminManage="isAdminManage"
                @changeShippingPartner="changeShippingPartner"
                @changeSignFromRecipient="changeSignFromRecipient"
                @calculatePriceUser="calculatePriceUser"
                @calculatePriceAdmin="calculatePriceAdmin"
                @reCreateLabel="$emit('reCreateLabel')"
            />

          </template>


          <template v-if="!$store.getters.getOrdersFBMCompanyLoading && $store.getters.getOrdersFBABtn">
            <div class="toggle-block__details w-100 ml-5">
              <div class="d-flex justify-content-center align-items-center">
                <DotsShadowPreloader/>
              </div>
            </div>
          </template>

<!--           &&-->
          <!--    FBM.data.useEasyPost      -->
<!--          -if="(isLabel || FBM.data.shippingAPIPrices && Object.keys(FBM.data.shippingAPIPrices).length)"-->
          <template v-else-if="!$store.getters.getOrdersFBABtn && _.has(getShippingPartner, 'shipping_methods')">
<!--                :getShippingPartner="getShippingPartner"-->
            <ShippingRates
                :FBM="FBM"
                :isAdminManage="isAdminManage"
                :shippingCompanyData="shippingCompanyData"
                @changeShippingAPIPrices="changeShippingAPIPrices"
                @changeSignFromRecipient="changeSignFromRecipient"
                @calculatePriceUser="calculatePriceUser"
                @calculatePriceAdmin="calculatePriceAdmin"
                @reCreateLabel="$emit('reCreateLabel')"
            />
          </template>

        </div>
      </div>
    </div>


    <video style="width: 0 !important; height: 0 !important; padding: 0 !important; margin: 0 !important;"></video>
  </div>

</template>

<script>
  // import RadioLabelPicture from "../../../../../../UI/radiobuttons/RadioLabelPicture/RadioLabelPicture";
  // import RadioDefault from "../../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import {FBMMixins} from "../../../../../../../mixins/FBMMixins/FBMMixins";
  import {APC_CAMBRIDGE, FBM_SHIPPING_TYPES} from "../../../../../../../staticData/staticVariables";
  // import MainButton from "@/components/UI/buttons/MainButton/MainButton";
  import DotsShadowPreloader
    from "../../../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
  // import TooltipBtn from "@/components/UI/tooltips/TooltipBtn/TooltipBtn";
  // import StatusIcoBtn from "@/components/UI/status/StatusIcoBtn/StatusIcoBtn";
  // import TooltipTextHelperPlugin from "@/components/coreComponents/TooltipTextHelperPlugin/TooltipTextHelperPlugin";
  import ShippingAdminBtns
    from "@/components/modules/OrdersFBMModule/components/components/chunks/Shipping2/ShippingAdminBtns/ShippingAdminBtns";
  import ShippingMethods
    from "@/components/modules/OrdersFBMModule/components/components/chunks/Shipping2/ShippingMethods/ShippingMethods";
  import ShippingRates
    from "@/components/modules/OrdersFBMModule/components/components/chunks/Shipping2/ShippingRates/ShippingRates";
  import ShippingCompany
    from "@/components/modules/OrdersFBMModule/components/components/chunks/Shipping2/ShippingCompany/ShippingCompany";

  export default {
    name: "Shipping2",

    components: {
      ShippingRates,
      ShippingMethods,
      ShippingAdminBtns,
      ShippingCompany,
      // TooltipTextHelperPlugin,
      // StatusIcoBtn,
      // TooltipBtn,
      DotsShadowPreloader,
      // MainButton,
      DefaultCheckbox,
      // RadioDefault,
      // RadioLabelPicture
    },

    mixins: [mixinDetictingMobile, FBMMixins],

    props: {
      FBM: Object,
      shippingCompanyData: Array,
      reloadKey: Number,
      isAdminManage: {
        type: Boolean,
        default: false
      }
    },

    watch: {
      // getShippingPartner(newVal) {
      //   if(newVal && this.isLabel){
      //     this.getShippingPrices(this.isAdminManage)
      //   }
      // },

      reloadKey(newVal){
        if(newVal && this.isLabel){
          this.getShippingPrices(this.isAdminManage)
        }
      },

      getVisibleVatNumber() {
        if(this.checkIsMarketplaceCompany && !this.isAdmin) {
          this.calculatePriceUser()
        }
      },

      /**
       * set Insurance from shipping company
       */
      getShippingPartnerType(newVal) {
        console.log(555);
        if(newVal && newVal?.insurance_price){
          this.FBM.setInsuranceFromDeliveryCompany(newVal.insurance_price)
        }
      },
    },

    computed: {
      getShippingPartner(){
        let partner = this._.find(this.shippingCompanyData, {delivery_company_id: this.FBM.data.shippingPartnerType.delivery_company_id})

        if(!partner) {
          partner = this._.first(this.shippingCompanyData)
          console.log(111);
          this.initShippingCompanies()
        }

        console.log(121212);
        return partner
      },

      getVisibleVatNumber() {
        return this.FBM.data.visibleVatNumber
      },

      getShippingPartnerType(){
        return this.FBM.data.shippingPartnerType
      }
    },


    data() {
      return {
        FBM_SHIPPING_TYPES: FBM_SHIPPING_TYPES,

        APC_CAMBRIDGE: APC_CAMBRIDGE,
      }
    },

    mounted() {
      /**
       * if label || has shipping API Prices (for admin manage)
       */
      if((this.isLabel || (this.FBM.data.shippingAPIPrices && Object.keys(this.FBM.data.shippingAPIPrices).length) || (this.isEasyType && this.isConsolidation))){
        this.FBM.data.useEasyPost = true
      }

      console.log(222);
      this.initShippingCompanies(this.isAdminManage)

    },

    methods: {

      // checkShippingPartnerRequireSignature(itemShippingPartner) {
      //   if(!this._.has(itemShippingPartner, 'requaire_signature')) return true
      //
      //   if(this.FBM.data.signFromRecipient && itemShippingPartner.requaire_signature) return true
      //
      //   if(!this.FBM.data.signFromRecipient && !itemShippingPartner.requaire_signature) return true
      //
      //   return false
      // },

      changeSignFromRecipient() {
        this.FBM.data.signFromRecipient = !this.FBM.data.signFromRecipient

        if(this.FBM.data.useEasyPost && (this.isLabel || this.isAdminManage)){
          this.getShippingPrices(this.isAdminManage)
          return
        }

        if(Number(this.FBM.data.shippingPartnerType.signature_price) === 0) {
          if(!this.FBM.data.signFromRecipient && this.FBM.data.shippingPartner?.requaire_signature) {
            // this.changeShippingPartner(this._.find(this.getShippingPartner['shipping_methods'], {requaire_signature: false}))
            switch (this.FBM.data.shippingPartner.id) {
              case 45:
                this.changeShippingPartner(this._.find(this.getShippingPartner['shipping_methods'], {id: 41}))
                break
              case 46:
                this.changeShippingPartner(this._.find(this.getShippingPartner['shipping_methods'], {id: 42}))
                break
              case 47:
                this.changeShippingPartner(this._.find(this.getShippingPartner['shipping_methods'], {id: 43}))
                break
              case 48:
                this.changeShippingPartner(this._.find(this.getShippingPartner['shipping_methods'], {id: 44}))
                break
            }
          }
          if(this.FBM.data.signFromRecipient && !this.FBM.data.shippingPartner?.requaire_signature) {
            // this.changeShippingPartner(this._.find(this.getShippingPartner['shipping_methods'], {requaire_signature: true}))
            switch (this.FBM.data.shippingPartner.id) {
              case 41:
                this.changeShippingPartner(this._.find(this.getShippingPartner['shipping_methods'], {id: 45}))
                break
              case 42:
                this.changeShippingPartner(this._.find(this.getShippingPartner['shipping_methods'], {id: 46}))
                break
              case 43:
                this.changeShippingPartner(this._.find(this.getShippingPartner['shipping_methods'], {id: 47}))
                break
              case 44:
                this.changeShippingPartner(this._.find(this.getShippingPartner['shipping_methods'], {id: 48}))
                break
            }
          }
          // this.checkShippingCompany().then(() => {
          //   this.initShippingCompanies(this.isAdminManage)
          //   if(this.FBM.data.useEasyPost === true) {
          //     this.getShippingPrices(this.isAdminManage)
          //   }
          // })
        }
      },

      calculatePriceAdmin() {
        this.FBM.data.useEasyPost = true
        this.getShippingPrices(this.isAdminManage)

        // Take a photo by web camera
        this.takePhoto()
      },

      calculatePriceUser() {
        this.getShippingPrices(this.isAdminManage)
      },

      shippingPriceRadioLabel(itemShippingAPIPrices) {
        let label = itemShippingAPIPrices.name + ' $' + itemShippingAPIPrices.shipmentCost + ' ' +
          `${itemShippingAPIPrices.warning_message ? itemShippingAPIPrices.warning_message : ''}`
        if(parseFloat(itemShippingAPIPrices.insuranceCost) > 0){
          label += ' insurance $' + itemShippingAPIPrices.insuranceCost
        }
        return label
      },

      changeShippingPartnerType(val){
        this.FBM.setShippingPartnerType(val)
        this.changeShippingAPIPrices({})
        this.changeShippingPartner(this._.first(val['shipping_methods']))

        // if(val['shipping_methods'].length === 1){
        // this.FBM.setShippingPartner(this._.first(val['shipping_methods']))
        // }
      },

      changeShippingPartner(val){
        this.FBM.setShippingPartner(val)

        // if(this.FBM.data.useEasyPost && (this.isLabel || this.isAdmin)){
          this.getShippingPrices(this.isAdminManage)
        // }
      },

      changeShippingAPIPrices(val){
        this.FBM.setShippingAPIPrice(val)
      },

      addressTypeeChange(){
        console.log('addressTypeeChange');
      },

      returnLabel() {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          let data = {
            "order_fbm_id" : this.FBM.data.Order.data.id
          }

          this.$store.dispatch('FBMLabelRefund', data).then((response) => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordChanged')
                this.$emit('reload')
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

    }

  }
</script>

<style scoped>
.loading-cost {
  pointer-events: none;
}

.big-label >>> .default-checkbox__label {
  font-size: 16px;
  line-height: 15px;
}

.label-red >>> .default-checkbox__label {
  color: #ff0c0c;
}

.shipping-block.disabled-btn {
  filter: none !important;
  pointer-events: none;
  opacity: 0.8;
}
</style>
