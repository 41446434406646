<template>
  <div class="fragment">
    <ExpressOrderCreation
        v-if="EO.data.Order.data.type === 'fedex'"
        :easyOrder="true"
    />
    <OrdersFBMCreation
        v-if="EO.data.Order.data.type === 'warehouse' || EO.data.Order.data.type === 'consolidation'"
        :easyOrder="true"
    />
  </div>
</template>

<script>
import {EasyOrder} from "@/components/modules/EasyOrdersModule/models/EasyOrder";
import ExpressOrderCreation
  from "@/components/modules/ExpressOrdersModule/components/ExpressOrderCreation/ExpressOrderCreation";
import OrdersFBMCreation from "@/components/modules/OrdersFBMModule/components/OrdersFBMCreation/OrdersFBMCreation";

export default {
  name: "EasyOrdersCreation",

  components: {OrdersFBMCreation, ExpressOrderCreation},

  data() {
    return {
      EO: new EasyOrder(),
    }
  },

  mounted() {
    if(this.$route.params.type) {
      this.EO.data.Order.setType(this.$route.params.type)
    }
  },

}
</script>

<style scoped>

</style>