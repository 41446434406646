<template>
  <!--@mouseover="isHovering = true"-->
  <!--@mouseout="isHovering = false"-->
  <!--:class="{hover: isHovering}"-->
  <div class="order-create__product custom-row"
       v-if="_.has($store.getters.getProformProductInitialize, 'proformCategories')"
  >
    <!--<div class="order-create__product-col custom-col" v-if="item.sku">-->
    <!--<DefaultSelect-->
    <!--:options="item.skuOption"-->
    <!--:label="'SKU'"-->
    <!--:selected="item.sku"-->
    <!--/>-->
    <!--</div>-->

    <div class="order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
         v-bind:class="{
          'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
          'custom-order-second': altVersion,
        }">
      <div class="admin-edit" @click="editTranslate([
                'proform_category',
                'product_IncludesProducts',
              ])"></div>
      <DefaultSelect
              :options="$store.getters.getProformProductInitialize.proformCategories"
              :label="$t('proform_category.localization_value.value')"
              :caption="categoryCaption ? $t('product_IncludesProducts.localization_value.value') : null"
              v-bind:class="{'ui-no-valid': productItem.validation.proformCategory}"
              :error="productItem.validation.proformCategory"
              :errorTxt="serverError ? productItem.validationTxt.proformCategory : $t(`${productItem.validationTranslate.proformCategory}.localization_value.value`)"
              :otherValue="'proformData'"
              :selected="productItem.product.proformData.proformCategory"
              @change="changeCategory"
      />
    </div>

    <div class="order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
         v-if="productItem.product.proformData.proformCategory && productItem.product.proformData.proformCategory.id === PROFORM_CATEGORY_SAVED"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
                'product_ProformFiles',
              ])"></div>
      <DefaultSelect
              :options="$store.getters.getSavedProform"
              :label="$t('product_ProformFiles.localization_value.value')"
              :otherValue="'savedProforms'"
              @change="changeSavedProform"

              v-bind:class="{'ui-no-valid': productItem.validation.proformNaming}"
              :error="productItem.validation.proformNaming"
              :errorTxt="$t(`${productItem.validationTranslate.proformNaming}.localization_value.value`)"
      />
    </div>

    <div class="order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
         v-if="productItem.product.proformData.proformCategory && productItem.product.proformData.proformCategory.id === PROFORM_CATEGORY_SEARCH"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
                'common_Search',
              ])"></div>
      <SearchSelect
              :options="options"
              :label="$t('common_Search.localization_value.value')"
              :typeSelect="'proformSearch'"
              :functionSearch="functionSearchProform"
              @change="changeSearchProform"

              v-bind:class="{'ui-no-valid': productItem.validation.proformNaming}"
              :error="productItem.validation.proformNaming"
              :errorTxt="$t(`${productItem.validationTranslate.proformNaming}.localization_value.value`)"
      />
    </div>

    <template
            v-if="_.has(productItem.product.proformData, 'proformTypes') &&
            Object.keys(productItem.product.proformData.proformTypes).length > 0"
    >
      <template v-if="localProformTypes = productItem.product.proformData.proformTypes">


        <template v-for="(itemType, index) in $store.getters.getProformProductInitialize.proformTypes">

          <div class="order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               :class="{
                  'custom-order-first' : (itemType.id === PROFORM_TYPE_NAMING && altVersion) || (itemType.id === PROFORM_TYPE_MATERIAL && altVersion)
               }"
               :key="index">
            <DefaultSelect
                v-if="(((itemType.id === PROFORM_TYPE_NAMING && productItem.product.proformData.proformCategory) || itemType.id !== PROFORM_TYPE_NAMING)
                && altVersion) || !altVersion"
                    v-bind:class="{
                      'disable-click' : !productItem.product.proformData.proformCategory,
                      'ui-no-valid': productItem.validation.proformMaterial && itemType.id === PROFORM_TYPE_MATERIAL && altVersion
                    }"
                    :options="localProformTypes[itemType.id].typeOptions"
                    :otherValue="'proformDataProduct'"
                    :label="getProformTypeLabel(localProformTypes, itemType)"
                    :disabled="Object.keys(productItem.product.proformData.proformCategory).length > 0 &&
                      !_.has(localProformTypes[itemType.id].typeOptions[0], 'proform_attachment_id')"
                    :selected="getSelectedProformItem(localProformTypes, itemType)"
                    :clearable="itemType.id === PROFORM_TYPE_SUBMATERIAL"
                    @change="(val) => changeSelect(val, itemType.id)"

                    :error="itemType.id === PROFORM_TYPE_MATERIAL && altVersion ? productItem.validation.proformMaterial : false"
                    :errorTxt="itemType.id === PROFORM_TYPE_MATERIAL && altVersion ?
                    $t(`${productItem.validationTranslate.proformMaterial}.localization_value.value`) : false"
            />
            <SearchSelect
                v-else-if="altVersion && itemType.id === PROFORM_TYPE_NAMING && !productItem.product.proformData.proformCategory"
                v-bind:class="{
                  'fix-big-label': itemType.id === PROFORM_TYPE_NAMING && altVersion
                }"
                :label="getProformTypeLabel(localProformTypes, itemType)"
                :typeSelect="'productNamingSearch'"
                :options="namingSearchOptions"
                :functionSearch="functionSearchProformNaming"
                @change="changeNamingProform"
            />
            <!--v-bind:class="{'ui-no-valid': productItem.validation.proformNaming}"-->
            <!--:error="productItem.validation.proformNaming"-->
            <!--:errorTxt="$t(`${productItem.validationTranslate.proformNaming}.localization_value.value`)"-->
          </div>
          <div class="order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               :class="{
                  'custom-order-second' : (itemType.id === PROFORM_TYPE_NAMING && altVersion) || (itemType.id === PROFORM_TYPE_MATERIAL && altVersion),
                  'custom-order-third' : itemType.id !== PROFORM_TYPE_NAMING && itemType.id !== PROFORM_TYPE_MATERIAL && altVersion,
               }"
               v-if="!isLatvian && !isUnitedKingdomCountry"
               :key="index+'1'">
            <DefaultInput
                    :label="localProformTypes[itemType.id].typeName[itemType.translationStorage.en.name + '_En'].name + '(Eng)'"
                    :type="'text'"
                    :value="localProformTypes[itemType.id].typeValue === '' ?
                      localProformTypes[itemType.id].typeValue :
                      Object.keys(localProformTypes[itemType.id].typeValue.value).length > 0 ?
                        localProformTypes[itemType.id].typeValue.value.translationStorage.en.name : ''"
                    :disabled="true"
            />
          </div>
        </template>

      </template>

    </template>

    <div class="order-create__product-col custom-col custom-col--md-100 custom-col--sm-100 mb-0 position-relative"
         v-bind:class="{
           'custom-col--66' : visibleHSCode,
           'custom-col--33' : !visibleHSCode,
         }"
    >
      <div class="order-create__product-row custom-row">
        <div class="order-create__product-col custom-col custom-col--50 custom-col--sm-100"
             v-if="visibleHSCode"
        >
          <div class="order-create__product-input"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                'common_ItemsQty',
              ])"></div>
            <DefaultInput
                    :label="$t('common_HSCode.localization_value.value')"
                    :disabled="!canChangeHSCode"
                    v-bind:class="{'ui-no-valid': productItem.validation.HSCode}"
                    :error="productItem.validation.HSCode"
                    :errorTxt="$t(`${productItem.validationTranslate.HSCode}.localization_value.value`)"
                    v-model="productItem.product.HSCode"
            />
          </div>
        </div>
        <div class="order-create__product-col custom-col custom-col--25 custom-col--sm-50"
             v-bind:class="{
               'custom-col--25' : visibleHSCode,
               'custom-col--50' : !visibleHSCode,
             }"
        >
          <div class="order-create__product-input"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                'common_ItemsQty',
              ])"></div>
            <InputQuantity
                    :value="productItem.product.itemsQuantity"
                    :label="$t('common_ItemsQty.localization_value.value')"
                    @change="changeValueQuantity"
                    v-bind:class="{'ui-no-valid': productItem.validation.itemsQuantity}"
                    :error="productItem.validation.itemsQuantity"
                    :errorTxt="$t(`${productItem.validationTranslate.itemsQuantity}.localization_value.value`)"
            />
          </div>
        </div>
        <div class="order-create__product-col custom-col custom-col--sm-50"
             v-bind:class="{
               'custom-col--25' : visibleHSCode,
               'custom-col--50' : !visibleHSCode,
             }"
        >
          <div class="order-create__product-input"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                'common_PricePerItem',
              ])"></div>
            <InputSum
                    :value="setPriceValue(productItem.product.price)"
                    :label="$t('common_PricePerItem.localization_value.value')"
                    :icoType="'dollar'"
                    @change="changeValueSum"
                    :placeholder="'0'"
                    v-bind:class="{'ui-no-valid': productItem.validation.price}"
                    :error="productItem.validation.price"
                    :errorTxt="$t(`common_MoreThanZero.localization_value.value`)"
            />
          </div>


<!--          <div class="order-create__product-add-to-profile"-->
<!--               v-if="!isMobileFunc() &&-->
<!--               productItem.product.proformData.proformCategory !== '' &&-->
<!--               !isAdmin &&-->
<!--               !hideAddProfile"-->
<!--               @click="addToProfile">-->
<!--            <template>-->
<!--              <v-popover-->
<!--                  class="site-tooltip"-->
<!--                  offset="5"-->
<!--                  placement="top"-->
<!--                  trigger="hover"-->
<!--              >-->
<!--                <span>-->
<!--                  <AddToProfileIco/>-->
<!--                </span>-->
<!--                <template slot="popover">-->
<!--                  <p>{{$t('common_AddProfile.localization_value.value')}}</p>-->
<!--                </template>-->
<!--              </v-popover>-->
<!--            </template>-->
<!--          </div>-->

          <div class="order-create__product-add-to-profile"
               v-if="!isMobileFunc() &&
               productItem.product.proformData.proformCategory !== '' &&
               !isAdmin &&
               !hideChangeProformProduct"
               @click="changeProformProduct">
            <template>
              <v-popover
                  class="site-tooltip"
                  offset="5"
                  placement="top"
                  trigger="hover"
              >
                <span>
                  <AddToProfileIco/>
                </span>
                <template slot="popover">
                  <p>{{$t('common_ChangeProduct.localization_value.value')}}</p>
                </template>
              </v-popover>
            </template>
          </div>




        </div>
      </div>
    </div>
    <div class="order-create__product-col custom-col custom-col--50" v-if="nameForLabel">
      <div class="order-create__product-input"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                'common_nameForLabel',
              ])"></div>
        <DefaultInput
            :label="$t('common_nameForLabel.localization_value.value')"
            v-model="productItem.product.nameForLabel"
        />
      </div>
    </div>

    <div class="custom-col"
         v-if="
         productItem.product.proformData.proformCategory !== '' &&
         !isAdmin &&
         !hideAddProfile"
         @click="addToProfile">
      <div class="order-create__product-add-to-profile position-static">

        <template>
          <div class="order-create__product-add-to-profile position-static">
            <span class="order-create__product-add-to-profile-mobile">
              <AddToProfileIco/>
              <div class="order-create__product-add-to-profile-mobile--text site-btn">{{$t('common_AddProfile.localization_value.value')}}</div>
            </span>
          </div>
        </template>
      </div>
    </div>

    <div class="custom-col"
         v-if="isMobileFunc() &&
         productItem.product.proformData.proformCategory !== '' &&
         !isAdmin &&
         !hideChangeProformProduct"
         @click="changeProformProduct">
      <div class="order-create__product-add-to-profile">

        <template>
          <div class="order-create__product-add-to-profile">
            <span class="order-create__product-add-to-profile-mobile">
              <AddToProfileIco/>
              <div class="order-create__product-add-to-profile-mobile--text site-btn">{{$t('common_ChangeProduct.localization_value.value')}}</div>
            </span>
          </div>
        </template>
      </div>
    </div>

    <div class="order-create__product-col custom-col"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
                          'product_Handmade',
                        ])"></div>
      <DefaultCheckbox
              class="wfc"
              :label="$t('product_Handmade.localization_value.value')"
              v-model="productItem.product.handMade"
      />
    </div>

    <div class="order-create__product-col custom-col position-relative mb-4"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
                          'product_UpdateProform',
                        ])"></div>
      <MainButton
          v-if="product.product.proformArchived || productItem.product.proformArchived"
          :value="$t('product_UpdateProform.localization_value.value')"
          class="buy-label__btn-i wfc mb-4"
          v-bind:class="{'disabled-btn' : $store.getters.getProformDataBtn}"
          @click.native="updateArchivedProform"
      />
      <span class="textarea-default__error error-field"
            style="right: auto; margin-left: 17px; margin-top: 5px; font-size: 14px;"
            v-if="productItem.validation.proformArchived">{{ $t(`${productItem.validationTranslate.proformArchived}.localization_value.value`) }}
      </span>
    </div>

  </div>
</template>

<script>


  import AddToProfileIco from '../../../../public/img/UI-group/add-to-profile-ico.svg?inline'
  import DefaultInput from "../../UI/inputs/DefaultInput/DefaultInput";
  import InputQuantity from "../../UI/inputs/InputQuantity/InputQuantity";
  import InputSum from "../../UI/inputs/InputSum/InputSum";
  import DefaultCheckbox from "../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import {mixinDetictingMobile} from "../../../mixins/mobileFunctions";
  import DefaultSelect from "../../UI/selectiones/DefaultSelect/DefaultSelect";
  import SearchSelect from "../../UI/selectiones/SearchSelect/SearchSelect";
  import {
    PROFORM_CATEGORY_SAVED,
    PROFORM_CATEGORY_SEARCH,
    PROFORM_TYPE_SUBMATERIAL,
    PROFORM_TYPE_NAMING,
    PROFORM_TYPE_MATERIAL
  } from "../../../staticData/staticVariables";
  import {productMixins} from "../../../mixins/productMixins/productMixins";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";

  export default {
    name: "ProductItemCreation",
    components: {
      MainButton,
      SearchSelect,
      DefaultInput,
      InputQuantity,
      InputSum,
      DefaultCheckbox,
      AddToProfileIco,
      DefaultSelect,
    },

    props: {
      product: Object,
      serverError: Boolean,
      canChangeHSCode: Boolean,
      visibleHSCode: {
        type: Boolean,
        default: true
      },
      hideAddProfile: {
        type: Boolean,
        default: false
      },
      hideChangeProformProduct: {
        type: Boolean,
        default: true
      },
      categoryCaption: {
        type: Boolean,
        default: true
      },
      nameForLabel: {
        type: Boolean,
        default: false
      },
      altVersion: {
        type: Boolean,
        default: false,
      }
    },

    mixins: [
      mixinDetictingMobile,
      productMixins,
    ],

    computed: {
      getProductId() {
        return this.product.product.productObject?.id
      },

      getC() {
        return this.product.product.proformArchived
      }
    },

    data() {
      return {
        PROFORM_TYPE_SUBMATERIAL: PROFORM_TYPE_SUBMATERIAL,
        PROFORM_TYPE_MATERIAL: PROFORM_TYPE_MATERIAL,
        PROFORM_TYPE_NAMING: PROFORM_TYPE_NAMING,
        PROFORM_CATEGORY_SEARCH: PROFORM_CATEGORY_SEARCH,
        PROFORM_CATEGORY_SAVED: PROFORM_CATEGORY_SAVED,
        tooltipAddProductActive: true,
        isHovering: false,
        productItem: this.product,
        options: [],
        namingSearchOptions: [],

        proformArchivedId: null,
      }
    },

    watch: {
      product: function (newVal) {
        this.productItem = newVal
      },

      getProductId() {
        this.proformArchivedId = null
      },

      getC(newVal) {
        console.log(1122334456);
        console.log(newVal);
      }
    },

    mounted() {

    },

    methods: {
      changeInput(val, item) {
        this.productItem.product.proformData.proformTypes[item] = {name: val}
        this.$emit('changeSomethingField')
      },

      changeSelect(val, item) {
        console.log(555);
        let data = this.productItem.product.proformData.proformTypes[item]
        data.typeValue = val
        this.$emit('changeProformSelectItem')
        this.$emit('changeSomethingField')
      },


      changeCategory(val, nameVal = '') {
        this.$emit('changeCategory', {val, nameVal})
        this.$emit('changeSomethingField')
      },

      changeValueQuantity(data) {
        this.productItem.setProductItemsQuantity(data)
        this.$emit('changeSomethingField')
      },

      changeValueSum(data) {
        if (parseFloat(data) > 100000) data = 10000000
        this.productItem.setProductPrice(data)
        this.$emit('changeSomethingField')
      },

      functionSearchProform(val, loading) {
        loading(true)

        if (val === '' || val.length < 3) {
          this.options = []
          loading(false)
          return
        }

        if (this.proformSearchTimeoutTime !== undefined) {
          let dateNow = new Date()
          if (dateNow - this.proformSearchTimeoutTime < 500) {
            clearTimeout(this.proformSearchTimeout)
          }
        }
        this.createTimeOut(val, loading)

      },

      functionSearchProformNaming(val, loading) {
        loading(true)

        if (val === '' || val.length < 3) {
          this.namingSearchOptions = []
          return
        }

        if (this.proformSearchTimeoutTime !== undefined) {
          let dateNow = new Date()
          if (dateNow - this.proformSearchTimeoutTime < 500) {
            clearTimeout(this.proformSearchTimeout)
          }
        }
        this.createTimeOut(val, loading, true)
      },

      createTimeOut(val, loading, namingSearch = false) {
        this.proformSearchTimeoutTime = new Date()
        this.proformSearchTimeout = setTimeout(() => {
          this.search(val, loading, namingSearch)
        }, 500)
      },

      search(val, loading, namingSearch = false) {
        if (namingSearch) {
          let langSearchParam = ''
          // if (this.isLatvian || this.isUnitedKingdomCountry) {
          //   langSearchParam = '&lang=en'
          // }
          if (/[a-z]/i.test(val[0])) {
            langSearchParam = '&lang=en'
          }
          this.$store.dispatch('fetchProductsForProformSearch', '?q='+val+langSearchParam).then((response) => {

            loading(false)

            this.namingSearchOptions = response.data.data
          })
        }
        else {
          this.$store.dispatch('fetchProformProductSearch', val).then((response) => {

            loading(false)

            let data = response.data.data
            let options = []

            data.map(item => {
              item.category.group = 'group'
              options.push(item.category)

              item.values.map(itemValue => {
                itemValue.category = item.category.id
                options.push(itemValue)
              })
            })

            this.options = options
          })
        }
      },

      changeSearchProform(val) {
        this.changeCategory(
          this._.find(this.$store.getters.getProformProductInitialize.proformCategories, {id: val.category}),
          val
        )
      },

      changeNamingProform(val) {
        this.changeCategory(
            this._.find(this.$store.getters.getProformProductInitialize.proformCategories, {id: val.proform_category.id}),
            {
              proform_attachment_id: val.proform_value_id,
              value: val.proform_value
            }
        )
        //clear after selecting item from search
        this.namingSearchOptions = []
      },

      addToProfile() {
        let data = this.productItem.prepareSaveProform()

        this.$store.dispatch('createSavedProform', data).then(response => {
          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationRecordCreated')
          } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;

            errors.record_exists ? this.openNotify('error', {txtServer: errors['record_exists'][0]}) : false;
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }

        })
      },

      changeProformProduct(){
        // console.log(32423);
        // console.log(this.product);
        this.updateProduct(false, true)
      },

      updateArchivedProform() {
        console.log(this.product);
        console.log(this.productItem);

        if(this.product.product.proformArchived && this.product.productProformValidation({
          productName: false,
          productObject: false,
          proformCategory: true,
          itemsQuantity: true,
          price: true,
          HSCode: true,
          productNameLatin: false,
          proformMaterial: true,
          proformArchived: true,
        })) {
          let data = this.product.prepareProduct(
              false,
              this.$store.getters.getIsAdminRights === 'user' ?
                  this.$store.getters.GET_COMMON_AUTHORIZED.user.id : ''
          )

          let proforma = this._.first(data.proform_data)
          let prepareData = {
            "ids" : proforma.ids,
            "handmade" : proforma.handmade,
            "item_quantity" : proforma.item_quantity,
            "item_price" : proforma.item_price,
            "hs_code" : data.hs_code,
          }

          if(this.proformArchivedId) {
            prepareData.profile_entity_id = this.proformArchivedId
          }

          if(this.product.product.productObject?.id) {
            prepareData.products_id = this.product.product.productObject?.id
          }

          console.log(prepareData);

          this.$store.dispatch('updateProfileProformAndProduct', prepareData).then(response => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.proformArchivedId = null
                this.product.product.proformArchived = false
                this.productItem.product.proformArchived = false
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

        }
      },

      changeSavedProform(val) {
        if(val?.archived === 1) {
          /**
           * show btn change saved proform and error
           */
          this.product.product.proformArchived = true
          this.productItem.product.proformArchived = true
          this.proformArchivedId = val.proform_entity_id
          this.$emit('changeCategory', {val: '', nameVal: ''})
          this.openNotify('error', 'common_notificationArchivedProform')
        } else {
          this.product.product.proformArchived = false
          this.productItem.product.proformArchived = false
        }
        this.productItem.changeSavedProform(val)
        this.$emit('changeSavedProform', val)
      },

      getProformTypeLabel(proformTypes, type) {
        // let user = this.$store.getters.getUserProfile,
        //   group = user.user_personal_contact?.country_group?.fromBelarusGroup
        if (this.altVersion && (type.id === PROFORM_TYPE_NAMING || type.id === PROFORM_TYPE_MATERIAL)) {
          return type.id === PROFORM_TYPE_NAMING ? this.$t('proform_namingAlt.localization_value.value') :
              this.$t('proform_materialAlt.localization_value.value');
        }

        if (this.isBelarus) {
          return proformTypes[type.id].typeName[type.translationStorage.en.name + '_Ru'].name + '(Рус)'
        }
        if (this.isLatvian || this.isUnitedKingdomCountry) {
          return proformTypes[type.id].typeName[type.translationStorage.en.name + '_En'].name + '(Eng)'
        }
        return proformTypes[type.id].typeName[type.translationStorage.en.name + '_Ua'].name + '(Укр)'
      },

      getSelectedProformItem(localProformTypes, itemType) {
        return localProformTypes[itemType.id].typeValue === '' || Object.keys(localProformTypes[itemType.id].typeValue.value).length > 0 ?
          localProformTypes[itemType.id].typeValue : ''
      },


    },
  }
</script>

<style scoped>
  .disabled-product {
    pointer-events: none;
    opacity: 0.5;
  }
  .position-static {
    position: static !important;
  }
</style>
