<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'express_orderId',
          'express_PayPalTransaction',
        ])"></div>
      <CardLeftBlock
              :name="$t('express_orderId.localization_value.value')"
              :value="'#' + ex.express.expressId">
      >
        <template slot="button">

          <div class="card-detail-left__date"
               v-if="_.has(ex.express.expressObj, 'expressOrderInstance')"
          >
            {{ ex.express.expressObj.expressOrderInstance.created_at | moment("DD MMM, YYYY") }}
          </div>
          <router-link :to="$store.getters.GET_PATHS.cusPayments + '?order=10382'"
                       v-if="ex.express.typeTransaction === 'payment'"
          >
            <MainButton
                    :ico="true"
                    :value="$t('express_PayPalTransaction.localization_value.value')"
                    class="card-detail-left__btn"
            >
              <template slot="ico">
                <PayPalIcon/>
              </template>
            </MainButton>
          </router-link>
          <div class="d-flex flex-wrap mt-4">
            <router-link v-if="ex.express.proformDocument && isAdmin"
                         :to="$store.getters.GET_PATHS.getPdfFile + '/' + ex.express.proformDocument.uuid +
                          '?print=true' + `&${getProformDocType}=${ex.express.expressId}`"
                         :target="'_blank'"
                         class="mr-2 mb-3"
                         v-bind:class="{'disabled-btn' : !ex.express.expressObj.enabled_user_proform_document}"
            >
              <MainButton
                  v-if="ex.express.type === DHL_DELIVERY_SERVICE.id"
                  :ico="true"
                  :value="$t('common_DocsDHL.localization_value.value')"
                  class="card-detail-left__btn btn-fit-content mt-0"
              />
              <MainButton
                  v-if="ex.express.type === FEDEX_DELIVERY_SERVICE.id"
                  :ico="true"
                  :value="$t('common_DocsFedEx.localization_value.value')"
                  class="card-detail-left__btn btn-fit-content mt-0"
              />
              <MainButton
                  v-if="ex.express.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id"
                  :ico="true"
                  :value="$t('common_DocsNPG.localization_value.value')"
                  class="card-detail-left__btn btn-fit-content mt-0"
              />
            </router-link>

            <MainButton
                v-if="isAdmin && _.has(this.currentPermissions, PERMISSIONS.ORDER_NOTIFICATION_GENERAL)"
                :value="$t('common_OrderNotification.localization_value.value')"
                class="card-detail-left__btn btn-fit-content mt-0"
                @click.native="$emit('openOrderNotification')"
            />
          </div>


          <div class="site-link brown text-decoration-underline mt-4">
            <div v-if="isAdmin && ex.express.historyOfChanged"
                 class="mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
              <div class="site-link text-decoration-underline btn-style brown"
                   @click="historyOfChangesFunc('express', ex.express.historyOfChanged)">
                {{$t('common_CheckHistory.localization_value.value')}}
              </div>
            </div>
          </div>


          <div class="user-card__head-row d-flex flex-column" v-if="isAdmin">
            <div class="amount-row light mb-1" v-if="getUserBalance">
              Balance:
              <span class="color-red important font-weight-bold m-0">
                $ {{getUserBalance}}
              </span>
            </div>
            <div class="amount-row light" v-if="getPayoneerBalance">
              Payoneer Balance:
              <span class="color-red important font-weight-bold m-0">
                $ {{getPayoneerBalance}}
              </span>
            </div>
          </div>

          <div class="user-card__head-row d-flex flex-column mt-5" v-if="isAdmin">
            <div class="custom-row" v-if="ex.express.downloadPhotos.length > 0">
              <div class="custom-col custom-col--50 custom-col--md-50 custom-col--sm-100"
                   v-for="(item, index) in ex.express.downloadPhotos"
                   :key="index"
              >
                <div class="site-document d-flex justify-content-center">
<!--                  <div class="site-document__remove" @click="removeDocument(ex.express.files[0].small_image)">-->
<!--                    <CloseIcon/>-->
<!--                  </div>-->
                  <div class="order-photo">
                    <img class="cursor-pointer" style="max-width: 100%;"
                         :src="'data:image/png;base64,' + item"
                         @click="showSingle('data:image/png;base64,' + item)" alt="img">
                  </div>
                </div>
              </div>

            </div>
          </div>


          <!-- all props & events -->
          <Lightbox
              escDisabled
              moveDisabled
              :visible="visible"
              :imgs="imgs"
              :index="index"
              @hide="visible = false"
          ></Lightbox>


        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">


            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'ex_backTo',
                ])"></div>
                <router-link v-if="isEasyType" :to="$store.getters.GET_PATHS.easyOrders" class="order-create__head-back">
                  <LinkBack
                      :value="$t('common_backTo.localization_value.value')"
                  />
                </router-link>
                <router-link v-else :to="$store.getters.GET_PATHS.expressOrders" class="order-create__head-back">
                  <LinkBack
                          :value="$t('ex_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>

            <div
                    v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'express_NewExpressOrder',
                ])"></div>
              <div class="order-create__head-title color-orange"
                   v-if="ex.express.type === TNT_DELIVERY_SERVICE.id">
                {{$t('express_NewExpressOrder.localization_value.value')}} (TNT)
              </div>
              <div class="order-create__head-title color-red"
                   v-if="ex.express.type === DHL_DELIVERY_SERVICE.id">
                {{$t('express_NewExpressOrder.localization_value.value')}} (DHL)
              </div>
              <div class="order-create__head-title color-blue"
                   v-if="ex.express.type === FEDEX_DELIVERY_SERVICE.id">
                <template v-if="isEasyType">{{$t('easy_express.localization_value.value')}}</template>
                <template v-else>{{$t('express_NewExpressOrder.localization_value.value')}} (FedEx)</template>
              </div>
              <div class="order-create__head-title color-blue"
                   v-if="ex.express.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id">
                {{$t('express_NewExpressOrder.localization_value.value')}} (Nova poshta)
              </div>

              <div class="order-create__head-txt"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'ex_pleaseFill',
                ])"></div>
                {{$t('ex_pleaseFill.localization_value.value')}}
              </div>
            </div>


            <div
                    v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                'express_PikupInfo',
                'express_ProductsDetails',
                'express_DeliveryDetails',
                'express_Packaging',
                'ex_tabsConfirmation',
              ])"></div>
              <div class="order-create__head-steps"
                   v-if="$store.getters.getIsAdminRights === 'user'">
                <ProgressSteps
                        :options="progressStepsSetting"
                        :step="activeStep"
                        @changeStep="stepChange"
                        @nextPageHandler="nextPageHandler"
                />
              </div>
            </div>

          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <div class="custom-row" v-if="$store.getters.getIsAdminRights === 'admin'">
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                        :serverError="serverError"
                        :validationUser="ex.validation.user"
                        :validationTxtUser="ex.validationTxt.user"
                        :validationTranslateUser="ex.validationTranslate.user"
                        :userSelected="ex.express.user"
                        @changeUser="changeUser"
                        :typeSelect="'users'"
                        :userEmail="true"
                        :copyName="true"
                />
              </div>
            </div>
          </div>

          <div class="order-create__section"
               v-if="activeStep == 1 || $store.getters.getIsAdminRights === 'admin'">

            <div class="order-create__section">
              <div class="order-create__section-label section-label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ex_sendFrom',])"></div>
                {{ $t('ex_sendFrom.localization_value.value') }}
              </div>
              <PickupInfo
                  :ex="ex"
                  :isEdit="true"
              />
              <NovaPoshtaSender
                  v-if="ex.express.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id"
                  :ex="ex"
              />
            </div>
          </div>
          <div class="order-create__section"
               v-if="activeStep == 2 || $store.getters.getIsAdminRights === 'admin'">
            <DeliveryDetails
                    :ex="ex"
                    :vatNumberValidate="ex.validation.vatNumber"
                    :isEdit="true"
            />
          </div>
          <div class="order-create__section"
               v-if="activeStep == 3 || $store.getters.getIsAdminRights === 'admin'">
            <ProductsDetails
                    :ex="ex"
                    :loadedProformOptions="loadedProformOptions"
                    :proformChanged="proformChanged"
                    :isEdit="true"
                    @changeCategory="({val, nameVal, item}) => changeCategoryProform({val, nameVal, item}, ex.express.delivery.delivery.country, true)"
                    @addProduct="$emit('addProduct')"
                    @removeProduct="i => $emit('removeProduct', i)"
            />
          </div>
          <div class="order-create__section"
               v-if="activeStep == 4 || $store.getters.getIsAdminRights === 'admin'">
            <Packaging
                    :ex="ex"
                    :isEdit="true"
            />

            <template v-if="isAdmin">
              <div class="order-create__product custom-row">
                <div class="order-create__product-col custom-col custom-col--50 custom-col--sm-100"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['express_status'])"></div>
                  <DefaultSelect
                      :options="expressStatusOptions"
                      :label="$t('express_status.localization_value.value')"
                      :optionsLabel="'name'"
                      :selected="getExpressStatusOption"
                      :clearable="false"
                      @change="changeExpressStatusOption"
                  />
<!--                      :disabled="true"-->
                </div>
                <div class="order-create__product-col custom-col custom-col--50 custom-col--sm-100"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['express_statusInvoice', 'express_statusInvoicePaid', 'express_statusInvoiceUnPaid'])"></div>
                  <DefaultSelect
                      :options="expressStatusInvoiceOptions"
                      :label="$t('express_statusInvoice.localization_value.value')"
                      :optionsLabel="'name'"
                      :selected="getExpressInvoiceStatusOption"
                      :clearable="false"
                      @change="changeExpressInvoiceStatusOption"
                  />
                </div>
              </div>
              <div class="order-create__product custom-row">
                <div class="order-create__product-col custom-col custom-col--33 custom-col--sm-50"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['express_InvoiceNetAmount'])"></div>
                  <DefaultInput
                      :label="$t('express_InvoiceNetAmount.localization_value.value')"
                      :value="ex.express.payedInvoiceNetAmount"
                      @input="changeExpressPayedInvoiceNetAmount"
                  />
                </div>
                <div class="order-create__product-col custom-col custom-col--33 custom-col--sm-50"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['express_InvoiceFeeAmount'])"></div>
                  <DefaultInput
                      :label="$t('express_InvoiceFeeAmount.localization_value.value')"
                      :value="ex.express.payedInvoiceFeeAmount"
                      @input="changeExpressPayedInvoiceFeeAmount"
                  />
                </div>
              </div>
            </template>

          </div>
          <div class="order-create__section"
               v-if="activeStep == 5 || $store.getters.getIsAdminRights === 'admin'"
          >
            <Confirmation
                    v-if="activeStep == 5 && $store.getters.getIsAdminRights !== 'admin'"
                    :ex="ex"
                    @changeStep="step => {activeStep = step}"
            />

            <div class="order-create__title-horizontal"
                 v-if="Object.keys(labelPrice).length > 0 || $store.getters.getExpressLoadingPrice"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
										'express_Shipping',
									])"></div>
              <TitleHorizontal
                      :value="$t('express_Shipping.localization_value.value')"
              />
            </div>

            <div class="pt-2 pb-3" v-if="$store.getters.getExpressLoadingPrice">
              <DotsShadowPreloader/>
            </div>

            <LabelPrice
                    v-if="Object.keys(labelPrice).length > 0 && !$store.getters.getExpressLoadingPrice && hasCreateLabel"
                    :labelPrice="labelPrice"
                    :bigCards="true"
                    :totalShippingCostTranslate="ex.express.type === FEDEX_DELIVERY_SERVICE.id ? 'common_TotalShippingCostExpress' : 'common_TotalShippingCost'"
                    :currentVariant="ex.express.variantLabelPrice"
                    :underTotalTxt="ex.express.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id ? 'express_periodDocuments' : null"
                    @changeVariant="changeVariant"
            />
                    <!--:costDelivery="ex.express.type !== FEDEX_DELIVERY_SERVICE.id"-->





          </div>
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'common_cancel',
                  'common_Previous',
                ])"></div>
              <router-link :to="$store.getters.GET_PATHS.easyOrders"
                           class="site-link site-link--alt"
                           v-if="activeStep == 1 && isEasyType"
              >
                {{ $t('common_cancel.localization_value.value') }}
              </router-link>
              <router-link :to="$store.getters.GET_PATHS.expressOrders"
                           class="site-link site-link--alt"
                           v-else-if="activeStep == 1"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>
              <span class="site-link site-link--alt"
                    @click="prevPageHandler()"
                    v-if="activeStep != 1"
              >
                {{$t('common_Previous.localization_value.value')}}
              </span>
            </div>
<!--            && isCompletedStatus(ex.express.expressStatus)-->
            <div class="order-create__footer-btn ml-auto"
                 v-if="isAdmin ">
              <MainButton
                  class="order-create__footer-btn-i wfc"
                  :value="$t('common_update.localization_value.value')"
                  @click.native="$emit('adminSave', {draft: false, createLabel: false}, false, true)"
                  v-bind:class="{'disabled-btn' : $store.getters.getExpressBtn}"
              />


              <MainButton
                  v-if="!hasCreateLabel &&
                        !isCompletedStatus(ex.express.expressStatus) && !isWaitForPayStatus(ex.express.expressStatus)"
                  class="order-create__footer-btn-i wfc"
                  :value="isUserHasEasyOrder ? $t('common_Next.localization_value.value') : $t('express_BuyLabel.localization_value.value')"
                  @click.native="getLabelPriceFromCard"
                  v-bind:class="{'disabled-btn' : $store.getters.getExpressBtn}"
              />
<!--              <div v-else-if="waitingMakingLabelStatus(ex.express.expressStatus)" style="display: none"></div>-->
              <MainButton
                  v-else-if="waitingMakingLabelStatus(ex.express.expressStatus)"
                  class="order-create__footer-btn-i wfc"
                  :value="$t('common_ConfirmLabel.localization_value.value')"
                  @click.native="confirmLabel(ex.express.expressId, ex.express.type, true)"
                  v-bind:class="{'disabled-btn' : $store.getters.getExpressBtn}"
              />
              <MainButton
                  v-else-if="hasCreateLabel &&
                        !isCompletedStatus(ex.express.expressStatus) && !isWaitForPayStatus(ex.express.expressStatus) &&
                        (Object.keys(labelPrice).length > 0 && parseFloat(labelPrice.price) !== 0)"
                  class="order-create__footer-btn-i wfc"
                  :value="(!hasTransaction(ex.express) && !isDHLNoTrn) ?
                          $t('express_ConfirmBuyLabel.localization_value.value') :
                          $t('express_CreateLabel.localization_value.value')"
                  @click.native="$emit('createLabel', $route.params.id)"
                  v-bind:class="{'disabled-btn' : $store.getters.getExpressBtn}"
              />
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-else-if="Object.keys($store.getters.getUserProfile).length > 0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'common_SaveInDrafts',
                  'common_drafts',
                  'common_savingInDrafts',
                  'common_Next',
                  'express_BuyLabel',
                ])"></div>

              <MainButton
                  class="order-create__footer-btn-i secondary"
                  :value="$t('common_SaveInDrafts.localization_value.value')"
                  :tooltip="true"
                  @click.native="showSaveInDraftsPopup"
                  v-if="(activeStep == 5 || $store.getters.getIsAdminRights === 'admin') &&
                      !isCompletedStatus(ex.express.expressStatus)"
              >
                <template slot="tooltip">
                  <p>
                    <b>{{$t('common_drafts.localization_value.value')}}</b>
                  </p>
                  <p>
                    {{$t('common_savingInDrafts.localization_value.value')}}
                  </p>
                </template>
              </MainButton>

              <MainButton
                  class="order-create__footer-btn-i"
                  :value="$t('common_Next.localization_value.value')"
                  @click.native="nextPageHandler()"
                  v-if="activeStep !== progressStepsSetting.length && $store.getters.getIsAdminRights === 'user'"
              />

              <MainButton
                      v-else-if="($store.getters.getIsAdminRights === 'admin') &&
                      waitingMakingLabelStatus(ex.express.expressStatus)"
                  class="order-create__footer-btn-i wfc"
                  :value="$t('common_ConfirmLabel.localization_value.value')"
                  @click.native="confirmLabel(ex.express.expressId, ex.express.type, true)"
                      v-bind:class="{'disabled-btn' : $store.getters.getExpressBtn}"
              />

              <MainButton
                      v-else-if="!hasCreateLabel &&
                        !isCompletedStatus(ex.express.expressStatus) && !isWaitForPayStatus(ex.express.expressStatus)"
                  class="order-create__footer-btn-i wfc"
                  :value="$t('express_BuyLabel.localization_value.value')"
                  @click.native="getLabelPriceFromCard"
                      v-bind:class="{'disabled-btn' : $store.getters.getExpressBtn}"
              />

              <MainButton
                      v-else-if="hasCreateLabel &&
                        !isCompletedStatus(ex.express.expressStatus) && !isWaitForPayStatus(ex.express.expressStatus) &&
                        (Object.keys(labelPrice).length > 0 && parseFloat(labelPrice.price) !== 0)"
                      class="order-create__footer-btn-i wfc"
                      :value="(!hasTransaction(ex.express) && !isDHLNoTrn) ?
                          $t('express_ConfirmBuyLabel.localization_value.value') :
                          $t('express_CreateLabel.localization_value.value')"
                      @click.native="$emit('createLabel', $route.params.id)"
                      v-bind:class="{'disabled-btn' : $store.getters.getExpressBtn}"
              />
                  <!--@click.native="showNoMoneyPopup"-->
            </div>
          </div>
        </template>
      </CardRightBlock>

    </div>

    <NoMoneyPopup
        @close="closeNoMoneyPopup"
        v-if="isModalNoMoneyPopup"
    />

    <CancelOrderCreation
        @close="closeCancelOrderCreation"
        v-if="isModalCancelOrderCreation"
    />

    <SaveInDraftsPopup
        @close="closeSaveInDraftsPopup"
        v-if="isModalSaveInDraftsPopup"
        @yes="saveInDraft(true)"
    />

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import PayPalIcon from "../../../../../../../public/img/company-icons-group/paypal-icon.svg?inline";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import ProgressSteps from '../../../../../UI/progress/ProgressSteps/ProgressSteps.vue'
  import NoMoneyPopup from "../../../popups/NoMoneyPopup/NoMoneyPopup";
  import CancelOrderCreation from "../../../popups/CancelOrderCreation/CancelOrderCreation.vue";
  import {proformMixins} from "../../../../../../mixins/proformMixins/proformMixins";
  import {
    AUSTRALIA_ID,
    CANADA_COUNTRY_ID,
    DHL_DELIVERY_SERVICE, EXPRESS_DELIVERY_STATUSES,
    FEDEX_DELIVERY_SERVICE, MEXICO_COUNTRY_ID, NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
    TNT_DELIVERY_SERVICE, USA_COUNTRY_ID
  } from "../../../../../../staticData/staticVariables";
  import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
  import SaveInDraftsPopup from "../../../../../coreComponents/Popups/SaveInDraftsPopup/SaveInDraftsPopup.vue";
  import {tabsMixins} from "../../../../../../mixins/creationPageMixins/tabsMixins";
  import {expressMixinsFromCard} from "../../../../../../mixins/expressMixins/expressMixin";
  import TitleHorizontal from "../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
  import LabelPrice from "../../../../../coreComponents/LabelPrice/LabelPrice";
  import {paymentBlockFunc} from "../../../../../../mixins/dashboardMixins/paymentBlockFunc";
  import DotsShadowPreloader from "../../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
  import {hsCodeMixin} from "../../../../../../mixins/commonMixins/hsCodeMixin";
  import {vatNumberMixin} from "../../../../../../mixins/commonMixins/vatNumberMixin";
  import Confirmation from "@/components/modules/ExpressOrdersModule/components/chunks/Confirmation/Confirmation";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
  import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
  import Packaging from "@/components/modules/ExpressOrdersModule/components/chunks/UserBlocks/Packaging/Packaging";
  import PickupInfo from "@/components/modules/ExpressOrdersModule/components/chunks/UserBlocks/PickupInfo/PickupInfo";
  import ProductsDetails
    from "@/components/modules/ExpressOrdersModule/components/chunks/UserBlocks/ProductsDetails/ProductsDetails";
  import DeliveryDetails
    from "@/components/modules/ExpressOrdersModule/components/chunks/UserBlocks/DeliveryDetails/DeliveryDetails";
  import NovaPoshtaSender
    from "@/components/modules/ExpressOrdersModule/components/chunks/UserBlocks/NovaPoshtaSender/NovaPoshtaSender";
  import Lightbox from 'vue-easy-lightbox'


  export default {
    name: "ExpressOrderEditUser",

    components: {
      NovaPoshtaSender,
      DefaultInput,
      DefaultSelect,
      DotsShadowPreloader,
      LabelPrice,
      TitleHorizontal,
      UserSelect,
      CardLeftBlock,
      MainButton,
      PayPalIcon,
      CardRightBlock,
      LinkBack,
      ProgressSteps,
      PickupInfo,
      ProductsDetails,
      DeliveryDetails,
      Packaging,
      Confirmation,
      NoMoneyPopup,
      CancelOrderCreation,
      SaveInDraftsPopup,
      Lightbox,
    },

    mixins: [
      proformMixins,
      hsCodeMixin,
      vatNumberMixin,
      tabsMixins,
      expressMixinsFromCard,
      paymentBlockFunc,
    ],

    props: {
      ex: Object,
      loadedProformOptions: Number,
      selectedCountry: {String, Object},
      hasCreateLabel: Boolean,
    },

    watch: {
      selectedCountry: function(country){
        if(!country?.id) return

        this.$store.dispatch('getCountryDataById', country.id).then(async () => {
          let countryWithCountryGroups = this.$store.getters.getCountryGroup

          if (this.ex.express.type === DHL_DELIVERY_SERVICE.id) {
            this.ex.setVisibleFreightCost(true)
          }
          if (country.id === USA_COUNTRY_ID || country.id === AUSTRALIA_ID || country.id === CANADA_COUNTRY_ID || country.id === MEXICO_COUNTRY_ID) {
            this.$store.dispatch('getRegionsByCountry', country.id).then(() => {
              this.setExpressRegion(this.$store.getters.getRegions)
            }).catch(error => this.createErrorLog(error))
          }
          if (this.ex.express.type === DHL_DELIVERY_SERVICE.id || this.ex.express.type === FEDEX_DELIVERY_SERVICE.id) {
            this.ex.setVisibleHSCode(true)
          }
          else {
            this.ex.setVisibleHSCode(this.getHSCodePermissionsByCountry(
                countryWithCountryGroups,
                this.ex.express.type === FEDEX_DELIVERY_SERVICE.id
            ))
          }


          /**
           * Visible Vat Number if order status "copy"
           */
          // if(this.ex.express.expressStatus === EXPRESS_DELIVERY_STATUSES.COPY_STATUS){
          //   this.ex.setVisibleAndIsVatNumber(this.getVatNumberPermissionsByCountry(countryWithCountryGroups, this.ex.express.type === FEDEX_DELIVERY_SERVICE.id))
          // } else {
          //   this.ex.setVisibleVatNumber(this.getVatNumberPermissionsByCountry(countryWithCountryGroups, this.ex.express.type === FEDEX_DELIVERY_SERVICE.id))
          // }

          let needVat = await this.getExpressVatNumber(country, Number(this.ex.express.type))
          if(this.ex.express.expressStatus === EXPRESS_DELIVERY_STATUSES.COPY_STATUS){
            this.ex.setVisibleAndIsVatNumber(needVat)
          } else {
            this.ex.setVisibleVatNumber(needVat)
          }

        }).catch(error => this.createErrorLog(error))
      },

      activeStep: function () {
        this.hasCreateLabel = false
      },

      labelPrice: function () {
        this.hasCreateLabel = true
        setTimeout(() => {
          document.getElementsByClassName('order-create__total')[0]
            .scrollIntoView({block: "center", behavior: "smooth"});
        },50)
      },
    },

    computed: {
      getExpressStatusOption() {
        return this._.find(this.expressStatusOptions, {value: this.ex.express.expressStatus})
      },
      getExpressInvoiceStatusOption() {
        return this._.find(this.expressStatusInvoiceOptions, {value: this.ex.express.expressInvoiceStatus})
      },

      getPayoneerBalance() {
        if(this.ex.express?.user?.user_balances && this._.find(this.ex.express.user.user_balances, {payment_system: 'payoneer'})) {
          return this._.find(this.ex.express.user.user_balances, {payment_system: 'payoneer'}).balance
        }
        return false
      },
      getUserBalance() {
        if(this.ex.express?.user?.balance) {
          return this.ex.express?.user?.balance
        }
        return false
      },
    },

    data() {
      return{
        progressStepsSetting: [
          this.$t('express_PikupInfo.localization_value.value'),
          this.$t('express_DeliveryDetails.localization_value.value'),
          this.$t('express_ProductsDetails.localization_value.value'),
          this.$t('express_Packaging.localization_value.value'),
          this.$t('ex_tabsConfirmation.localization_value.value'),
        ],

        TNT_DELIVERY_SERVICE: TNT_DELIVERY_SERVICE,
        DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
        FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
        NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE: NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,

        expressStatusOptions: [
          {name: 'Completed', value: EXPRESS_DELIVERY_STATUSES.COMPLETED_STATUS},
          {name: 'In Progress', value: EXPRESS_DELIVERY_STATUSES.IN_PROGRESS_STATUS},
          {name: 'Wait for pay', value: EXPRESS_DELIVERY_STATUSES.WAIT_FOR_PAY_STATUS},
          {name: 'Wait for making label', value: EXPRESS_DELIVERY_STATUSES.WAIT_FOR_MAKING_LABEL_STATUS},
        ],

        expressStatusInvoiceOptions: [
          {name: this.$t('express_statusInvoicePaid.localization_value.value'), value: EXPRESS_DELIVERY_STATUSES.COMPLETED_STATUS},
          {name: this.$t('express_statusInvoiceUnPaid.localization_value.value'), value: EXPRESS_DELIVERY_STATUSES.IN_PROGRESS_STATUS},
        ],

        activeStep: 1,

        serverError: false,

        isModalNoMoneyPopup: false,
        isModalCancelOrderCreation: false,
        isModalSaveInDraftsPopup: false,

        labelPrice: {},

        proformChanged: false,

        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, // default: 0
      }
    },

    methods:{

      showSingle(data) {
        this.imgs = []
        this.imgs.push({
          title: 'img',
          src: data
        })
        this.visible = true
      },

      changeExpressStatusOption(val) {
        this.ex.express.expressStatusChanged = val.value
        this.ex.express.changedAdminFields = true
      },

      changeExpressInvoiceStatusOption(val) {
        this.ex.express.expressInvoiceStatus = val.value
        this.ex.express.changedAdminFields = true
      },

      changeExpressPayedInvoiceNetAmount(val) {
        this.ex.express.payedInvoiceNetAmount = val
        this.ex.express.changedAdminFields = true
      },

      changeExpressPayedInvoiceFeeAmount(val) {
        this.ex.express.payedInvoiceFeeAmount = val
        this.ex.express.changedAdminFields = true
      },

      showNoMoneyPopup() {
        this.isModalNoMoneyPopup = true
      },

      closeNoMoneyPopup() {
        this.isModalNoMoneyPopup= false
      },

      showCancelOrderCreation() {
        this.isModalCancelOrderCreation = true
      },

      closeCancelOrderCreation() {
        this.isModalCancelOrderCreation= false
      },

      showSaveInDraftsPopup() {
        this.isModalSaveInDraftsPopup = true
      },

      closeSaveInDraftsPopup() {
        this.isModalSaveInDraftsPopup = false
      },

    }
  }
</script>

<style scoped>
.order-photo {
  max-width: 100%;
  max-height: 150px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
