<template>
  <div class="fragment">
    <EasyOrdersTableSection
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        @changeTab="changeTab"
        @resetFilter="resetFilter"
        @changeFilter="changeFilter"
        @showMore="showMore"
        @reload="reload"
        @deleteOrder="(item) => deleteEasyOrder(item)"
        @thermalPrint="thermalPrint"
    />
<!--        @copyOrder="(id) => copyOrder(id, true)"-->
<!--        @royalMailManifest="royalMailManifest"-->
<!--        @generatePdf="generateICreateLabelFile"-->
<!--        @generateBarcodePdf="generateICreateLabelFile(true)"-->
<!--        @getMailLabel="getMailLabel"-->
<!--        @downloadFiles="exportFile"-->
<!--        @payFBM="payFBM"-->
<!--        @getLabelFBM="order => openLabelFBM(order)"-->
<!--        @getBarcodeFBM="order => getBarcodeFBM(order)"-->
<!--        @reloadLabelFBM="order => reloadLabelFBM(order)"-->
<!--        @paginate="paginate"-->
<!--        @changePerPage="changePerPage"-->
<!--        @cancelPrepayOrder="cancelPrepayOrder"-->

  </div>
</template>

<script>
import EasyOrdersTableSection
  from "@/components/modules/EasyOrdersModule/components/EasyOrdersTable/EasyOrdersTableSection/EasyOrdersTableSection";
import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";
import {FBMMixins} from "@/mixins/FBMMixins/FBMMixins";
import {EASY_ORDER_TYPES, EASY_ORDER_TYPES_ARRAY} from "@/staticData/staticVariables";
// import {paymentBlockFunc} from "@/mixins/dashboardMixins/paymentBlockFunc";
export default {
  name: "EasyOrdersTable",
  components: {EasyOrdersTableSection},

  mixins: [routeFilter, FBMMixins],

  data() {
    return {
      forPage: this.$store.getters.getEasyOrdersForPage,
      page: 1,
      onePage: 1,
      filterType: '',
      filterDate: [],
      filterUserName: '',
      filterUserId: '',
      filterId: '',
      // filterSentDate: [],
      // filterRecipient: '',
      // filterPaypalTransaction: '',
      // filterLots: '',
      // filterShip: '',
      // filterTrackingNumber: '',
      // filterConsoTrackingNumber: '',
      // filterConsoId: '',
      // filterShippingCompanyId: '',
      // filterShippingCompanyName: '',
      // filterReturnGoods: '',
      // filterPaid: '',
      // filterExpressTrack: '',
      // filterNpTrack: '',

      countFilterParams: 0,
      filterGetParams: {},

      navTabs: {
        all: {
          active: true,
          // label: this.$t('FBM_allOrders.localization_value.value'),
          label: 'easy_allOrders',
          name: 'all',
          id: false,
          permissionVisible: true,
        },
        [EASY_ORDER_TYPES.EASY_ORDER_WAREHOUSE_TYPE.name]: {
          active: false,
          // label: this.$t('FBM_warehouse.localization_value.value'),
          label: 'easy_warehouse',
          name: EASY_ORDER_TYPES.EASY_ORDER_WAREHOUSE_TYPE.name,
          id: EASY_ORDER_TYPES.EASY_ORDER_WAREHOUSE_TYPE.id,
          permissionVisible: true,
        },
        [EASY_ORDER_TYPES.EASY_ORDER_ECONOM_TYPE.name]: {
          active: false,
          // label: this.$t('FBM_warehouse.localization_value.value'),
          label: 'easy_econom',
          name: EASY_ORDER_TYPES.EASY_ORDER_ECONOM_TYPE.name,
          id: EASY_ORDER_TYPES.EASY_ORDER_ECONOM_TYPE.id,
          permissionVisible: true,
        },
        [EASY_ORDER_TYPES.EASY_ORDER_EXPRESS_TYPE.name]: {
          active: false,
          // label: this.$t('FBM_warehouse.localization_value.value'),
          label: 'easy_express',
          name: EASY_ORDER_TYPES.EASY_ORDER_EXPRESS_TYPE.name,
          id: EASY_ORDER_TYPES.EASY_ORDER_EXPRESS_TYPE.id,
          permissionVisible: true,
        },
      },

      // isPayOrderPopup: false,
      // payOrderPopupData: null,
      //
      // serverError: false,
      // authorizePayData: {},
      // authorizePaySum: -1,
      // authorizeBalanceAmount: -1,
      // bankFeeds: null,
      // authorizePaymentPopup: false,
      // braintreePaymentPopup: false,
      // thankYouPopupPopup: false,
      // authorizeError: false,
      //
      // isSendFromDataPopup: false,
    }
  },

  mounted() {
    if(this.$route.query.type !== undefined){
      this.navTabs.all.active = false
      this.navTabs[this.$route.query.type].active = true
    }

    this.filter()
  },

  methods: {
    getFilterParam() {
      this.filterGetParams = {
        page: this.$route.query.page,
        count: this.$route.query.count,
        pagination: this.$route.query.pagination,
        date: this.$route.query.date,
        type: this.$route.query.type,
        userName: this.$route.query.userName,
        userId: this.$route.query.userId,

        id: this.$route.query.id,
        // sentDate: this.$route.query.sentDate,
        // recipient: this.$route.query.recipient,
        // paypalTransaction: this.$route.query.paypalTransaction,
        // lots: this.$route.query.lots,
        // ship: this.$route.query.ship,
        // trackingNumber: this.$route.query.trackingNumber,
        // consoTrackingNumber: this.$route.query.consoTrackingNumber,
        // consoId: this.$route.query.consoId,
        // shippingCompanyId: this.$route.query.shippingCompanyId,
        // shippingCompanyName: this.$route.query.shippingCompanyName,
        // returnGoods: this.$route.query.returnGoods,
        // paid: this.$route.query.paid,
        // expressTrack: this.$route.query.expressTrack,
        // npTrack: this.$route.query.npTrack,
      }
    },

    filter(next = false) {
      this.getFilterParam()

      let url

      let paginationFlag = false
      if (this.$route.query?.pagination === 'true') {
        paginationFlag = true
      }

      // generate main filter if has get params in URL
      let generateMainFilter = this.generateMainFilterFromUrl(next, this, paginationFlag)

      // generate other filter if has get params in URL
      this.generateParamsFilterFromUrl(this)

      // Generate filter URL
      url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
      )

      if (next) this.$store.commit('setNextEasyOrdersPage', true)
      this.$store.dispatch('fetchEasyOrders', url).then(() => {
        this.$store.commit('setNextEasyOrdersPage', false)
      })


      this.checkCountFilter(['user', 'type', 'shippingCompanyName'])
    },

    generateFilterUrl(page, forPage, exportIds = false) {
      let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

      console.log(exportIds);

      this.generateFilterQueryParams(
          myQuery,
          {
            // filterType: 'order_type',
            // filterUserId: 'user_id',
            filterId: 'id',
            // filterRecipient: 'OrderFbmRecipientContactName',
            // filterPaypalTransaction: 'transaction_number',
            // // filterLots: 'proform_entity_naming',
            // filterShip: 'OrderFbmDeliveryCost',
            // filterTrackingNumber: 'OrderFbmTrackingNumber',
            // filterConsoTrackingNumber: 'consolidationUserTrackingNumber',
            // filterConsoId: 'consolidationId',
            // filterShippingCompanyId: 'shippingCompany',
            // filterReturnGoods: 'byReturnParcel',
            // filterExpressTrack: 'OrderFbmExternalNumber',
            // filterNpTrack: 'OrderFbmUserTrackingNumber',
          },
      )

      if(this.filterType && this.navTabs[this.filterType]?.id) {
        let value = EASY_ORDER_TYPES_ARRAY.find(i => {return i.id === this.navTabs[this.filterType].id})?.value
        myQuery.where('order_type', value)
      }

      // let deliveryTypeId = ''
      // switch (this.filterType) {
      //   case EASY_ORDER_TYPES.EASY_ORDER_WAREHOUSE_TYPE.name:
      //     deliveryTypeId = EASY_ORDER_TYPES.EASY_ORDER_WAREHOUSE_TYPE.id
      //     break
      //   case EASY_ORDER_TYPES.EASY_ORDER_ECONOM_TYPE.name:
      //     deliveryTypeId = EASY_ORDER_TYPES.EASY_ORDER_ECONOM_TYPE.id
      //     break
      //   case EASY_ORDER_TYPES.EASY_ORDER_EXPRESS_TYPE.name:
      //     deliveryTypeId = EASY_ORDER_TYPES.EASY_ORDER_EXPRESS_TYPE.id
      //     break
      // }
      // if (deliveryTypeId !== '')
      //   myQuery.where('order_type', deliveryTypeId)

      // if (this.filterPaid !== '' && this.filterPaid !== '-1')
      //   myQuery.where('OrderFbmPrepay', this.filterPaid)
      //
      // if (this.filterDate.length > 0) {
      //   let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')
      //   myQuery.whereIn(
      //       'between_created_at',
      //       date
      //   )
      // }

      // if (this.filterSentDate.length > 0) {
      //   let deliveryDate = this.generateDateForFilter(this.filterSentDate, 'YYYY-MM-DD')
      //   myQuery.whereIn(
      //       // 'betweenUnionSentAt',
      //       'between_delivery_date',
      //       deliveryDate
      //   )
      //   myQuery.where('OrderFbmStatus', 'completed')
      // }
      //
      // // If generate on export file
      // if(exportIds && exportIds.length > 0) {
      //   myQuery.whereIn('OrderFbmId', exportIds)
      // }

      return this.generateGetParamsFromQuery(myQuery, forPage, page)
    },

    /**
     * Delete Order by Id
     */
    deleteEasyOrder(item) {

      let text = {
        title: 'importO_deleteOrder',
        txt: 'importO_deleteOrderQuest',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        let dispatch = 'deleteEasyOrdersFBM'

        if(item.order_type === 'express_fedex') {
          dispatch = 'deleteEasyOrdersExpress'
        }

        if(item.order_type === 'econom') {
          dispatch = 'deleteEasyOrdersFBMConsolidation'
        }

        this.$store.dispatch(dispatch, item.order_id).then((response) => {
          if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

          this.reload()
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    thermalPrint(data) {
      let exportIds = this.getCheckedRows('row-name').join(',')

      if(!Object.hasOwnProperty.call(data, 'selected')) {
        exportIds = data.id
      }

      if (exportIds.length === 0) return

      let postData = {
        orderIds: exportIds,
        format: 'pdf'
      }

      let routeData = this.$router.resolve({
        path: this.$store.getters.GET_PATHS.generatePdfFile + '/fbm-order-thermal-print',
        query: {
          ids: postData.orderIds,
          format: postData.format
        }
      })

      window.open(routeData.href, '_blank');
    },

  },



}
</script>

<style scoped>

</style>