<template>
  <div class="detail-cards">
    <div class="detail-cards__inner">
      <div class="detail-cards__content">

        <div class="detail-cards__section">
          <PickupInfoWrap
              :ex="ex"
              :isEdit="true"
          />
        </div>
        <div class="detail-cards__section">
          <DeliveryDetailsWrap
              :ex="ex"
              :vatNumberValidate="ex.validation.vatNumber"
              :isEdit="true"
          />
        </div>
        <div class="detail-cards__section">
          <ProductsDetailsWrap
              :ex="ex"
              :loadedProformOptions="loadedProformOptions"
              :proformChanged="proformChanged"
              :isEdit="true"
              @changeCategory="({val, nameVal, item}) => changeCategoryProform({val, nameVal, item}, ex.express.delivery.delivery.country, true)"
              @addProduct="$emit('addProduct')"
              @removeProduct="i => $emit('removeProduct', i)"
          />
        </div>
        <div class="detail-cards__section">
          <Packaging
              :ex="ex"
          />
        </div>

        <div class="detail-cards__section">
          <AccordBlock v-if="Object.keys(labelPrice).length > 0 || $store.getters.getExpressLoadingPrice">
            <template slot="header">
              <div class="order-create__title-horizontal"
                   v-if="Object.keys(labelPrice).length > 0 || $store.getters.getExpressLoadingPrice"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
										'express_Shipping',
									])"></div>
                {{$t('express_Shipping.localization_value.value')}}
              </div>
            </template>
            <template slot="body">
              <div class="pt-2 pb-3" v-if="$store.getters.getExpressLoadingPrice">
                <DotsShadowPreloader/>
              </div>

              <template v-if="Object.keys(labelPrice).length > 0 && !$store.getters.getExpressLoadingPrice">
                <div class="date-time"
                     v-if="_.has(labelPrice, 'estimated_time_of_arrival') && labelPrice.estimated_time_of_arrival"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['express_EstimatedTimeOfArrival',])"></div>
                  {{$t('express_EstimatedTimeOfArrival.localization_value.value')}}
                  {{labelPrice.estimated_time_of_arrival | moment("DD/MM/YYYY H:mm:ss")}}
                </div>

                <div class="express-price-block-scrollto">
                  <LabelPrice
                      v-if="Object.keys(labelPrice).length > 0 && !$store.getters.getExpressLoadingPrice && hasCreateLabel"
                      :labelPrice="labelPrice"
                      :bigCards="true"
                      :totalShippingCostTranslate="ex.express.type === FEDEX_DELIVERY_SERVICE.id ? 'common_TotalShippingCostExpress' : 'common_TotalShippingCost'"
                      :currentVariant="ex.express.variantLabelPrice"
                      :underTotalTxt="ex.express.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id ? 'express_periodDocuments' : null"
                      @changeVariant="changeVariant"
                  />
                </div>
                <!--:costDelivery="ex.express.type !== FEDEX_DELIVERY_SERVICE.id"-->


              </template>
            </template>
          </AccordBlock>





        </div>

        <div class="detail-cards__section detail-cards__section--btn">
          <div class="detail-cards__section-col">
            <router-link :to="$store.getters.GET_PATHS.expressOrders">
              <span class="site-link site-link--alt brown">
              {{$t('common_cancel.localization_value.value')}}
              </span>
            </router-link>
          </div>
          <div class="detail-cards__section-col">
            <MainButton
                class="order-create__footer-btn-i secondary"
                :value="$t('common_SaveInDrafts.localization_value.value')"
                :tooltip="true"
                @click.native="showSaveInDraftsPopup"
                v-if="!isCompletedStatus(ex.express.expressStatus)"
            >
              <template slot="tooltip">
                <p>
                  <b>{{$t('common_drafts.localization_value.value')}}</b>
                </p>
                <p>
                  {{$t('common_savingInDrafts.localization_value.value')}}
                </p>
              </template>
            </MainButton>

            <MainButton
                v-if="($store.getters.getIsAdminRights === 'admin') &&
                      waitingMakingLabelStatus(ex.express.expressStatus)"
                class="order-create__footer-btn-i wfc"
                :value="$t('common_ConfirmLabel.localization_value.value')"
                @click.native="confirmLabel(ex.express.expressId, ex.express.type, true)"
                v-bind:class="{'disabled-btn' : $store.getters.getExpressBtn}"
            />

            <MainButton
                v-else-if="!hasCreateLabel &&
                        !isCompletedStatus(ex.express.expressStatus) && !isWaitForPayStatus(ex.express.expressStatus)"
                class="order-create__footer-btn-i wfc"
                :value="$t('express_BuyLabel.localization_value.value')"
                @click.native="tryGetLabel"
                v-bind:class="{'disabled-btn' : $store.getters.getExpressBtn}"
            />

            <MainButton
                v-else-if="hasCreateLabel &&
                        !isCompletedStatus(ex.express.expressStatus) && !isWaitForPayStatus(ex.express.expressStatus) &&
                        (Object.keys(labelPrice).length > 0 && parseFloat(labelPrice.price) !== 0)"
                class="order-create__footer-btn-i wfc"
                :value="(!hasTransaction(ex.express) && !isDHLNoTrn) ?
                          $t('express_ConfirmBuyLabel.localization_value.value') :
                          $t('express_CreateLabel.localization_value.value')"
                @click.native="$emit('createLabel', $route.params.id)"
                v-bind:class="{'disabled-btn' : $store.getters.getExpressBtn}"
            />
          </div>
        </div>
      </div>

      <div class="detail-cards__aside">
        <div class="detail-cards__aside-inner">
          <OrderCard
              v-if="loadUserComplete"
              :ex="ex"
              :user="ex.express.user"
          />
        </div>
      </div>
    </div>

    <NoMoneyPopup
        @close="closeNoMoneyPopup"
        v-if="isModalNoMoneyPopup"
    />

    <CancelOrderCreation
        @close="closeCancelOrderCreation"
        v-if="isModalCancelOrderCreation"
    />

    <SaveInDraftsPopup
        @close="closeSaveInDraftsPopup"
        v-if="isModalSaveInDraftsPopup"
        @yes="trySaveInDraft(true)"
    />
  </div>
</template>

<script>
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import Packaging from "./Packaging/Packaging.vue";
  import NoMoneyPopup from "../../../popups/NoMoneyPopup/NoMoneyPopup";
  import CancelOrderCreation from "../../../popups/CancelOrderCreation/CancelOrderCreation.vue";
  import {proformMixins} from "../../../../../../mixins/proformMixins/proformMixins";
  import {
    AUSTRALIA_ID,
    CANADA_COUNTRY_ID,
    DHL_DELIVERY_SERVICE, EXPRESS_DELIVERY_STATUSES,
    FEDEX_DELIVERY_SERVICE, MEXICO_COUNTRY_ID, NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE, PROFORM_TYPE_NAMING,
    TNT_DELIVERY_SERVICE, USA_COUNTRY_ID
  } from "../../../../../../staticData/staticVariables";
  import SaveInDraftsPopup from "../../../../../coreComponents/Popups/SaveInDraftsPopup/SaveInDraftsPopup.vue";
  import {tabsMixins} from "../../../../../../mixins/creationPageMixins/tabsMixins";
  import {expressMixinsFromCard} from "../../../../../../mixins/expressMixins/expressMixin";
  import LabelPrice from "../../../../../coreComponents/LabelPrice/LabelPrice";
  import {paymentBlockFunc} from "../../../../../../mixins/dashboardMixins/paymentBlockFunc";
  import DotsShadowPreloader from "../../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
  import {hsCodeMixin} from "../../../../../../mixins/commonMixins/hsCodeMixin";
  import {vatNumberMixin} from "../../../../../../mixins/commonMixins/vatNumberMixin";
  import OrderCard from "./OrderCard/OrderCard";
  import AccordBlock from "../../../../../coreComponents/AccordBlock/AccordBlock";
  import PickupInfoWrap
    from "@/components/modules/ExpressOrdersModule/components/chunks/UserBlocks/PickupInfoWrap/PickupInfoWrap";
  import ProductsDetailsWrap
    from "@/components/modules/ExpressOrdersModule/components/chunks/UserBlocks/ProductsDetailsWrap/ProductsDetailsWrap";
  import DeliveryDetailsWrap
    from "@/components/modules/ExpressOrdersModule/components/chunks/UserBlocks/DeliveryDetailsWrap/DeliveryDetailsWrap";


  export default {
    name: "ExpressOrderEditAdvancedUser",

    components: {
      DeliveryDetailsWrap,
      AccordBlock,
      OrderCard,
      DotsShadowPreloader,
      LabelPrice,
      MainButton,
      PickupInfoWrap,
      ProductsDetailsWrap,
      Packaging,
      NoMoneyPopup,
      CancelOrderCreation,
      SaveInDraftsPopup,
    },

    mixins: [
      proformMixins,
      hsCodeMixin,
      vatNumberMixin,
      tabsMixins,
      expressMixinsFromCard,
      paymentBlockFunc,
    ],

    props: {
      ex: Object,
      loadedProformOptions: Number,
      selectedCountry: {String, Object},
    },

    watch: {
      selectedCountry: function(country){
        if(!country?.id) return

        this.$store.dispatch('getCountryDataById', country.id).then(async () => {
          let countryWithCountryGroups = this.$store.getters.getCountryGroup

          if (this.ex.express.type === DHL_DELIVERY_SERVICE.id) {
            this.ex.setVisibleFreightCost(true)
          }
          if (country.id === USA_COUNTRY_ID || country.id === AUSTRALIA_ID || country.id === CANADA_COUNTRY_ID || country.id === MEXICO_COUNTRY_ID) {
            this.$store.dispatch('getRegionsByCountry', country.id).then(() => {
              this.setExpressRegion(this.$store.getters.getRegions)
            }).catch(error => this.createErrorLog(error))
          }
          if (this.ex.express.type === DHL_DELIVERY_SERVICE.id || this.ex.express.type === FEDEX_DELIVERY_SERVICE.id) {
            this.ex.setVisibleHSCode(true)
          }
          else {
            this.ex.setVisibleHSCode(this.getHSCodePermissionsByCountry(
                countryWithCountryGroups,
                this.ex.express.type === FEDEX_DELIVERY_SERVICE.id
            ))
          }


          /**
           * Visible Vat Number if order status "copy"
           */
          // if(this.ex.express.expressStatus === EXPRESS_DELIVERY_STATUSES.COPY_STATUS){
          //   this.ex.setVisibleAndIsVatNumber(this.getVatNumberPermissionsByCountry(countryWithCountryGroups, this.ex.express.type === FEDEX_DELIVERY_SERVICE.id))
          // } else {
          //   this.ex.setVisibleVatNumber(this.getVatNumberPermissionsByCountry(countryWithCountryGroups, this.ex.express.type === FEDEX_DELIVERY_SERVICE.id))
          // }
          let needVat = await this.getExpressVatNumber(country, Number(this.ex.express.type))
          if(this.ex.express.expressStatus === EXPRESS_DELIVERY_STATUSES.COPY_STATUS){
            this.ex.setVisibleAndIsVatNumber(needVat)
          } else {
            this.ex.setVisibleVatNumber(needVat)
          }


        }).catch(error => this.createErrorLog(error))
      },

      activeStep: function () {
        this.hasCreateLabel = false
      },

      labelPrice: function () {
        this.hasCreateLabel = true
        setTimeout(() => {
          document.getElementsByClassName('express-price-block-scrollto')[0]
            .scrollIntoView({block: "center", behavior: "smooth"});
        },50)
      },

      user: function () {
        if (!this.isAdmin && this.$store.getters.GET_COMMON_AUTHORIZED?.user?.small_avatar_file
            && this.$store.getters.GET_COMMON_AUTHORIZED?.user?.small_avatar_file.length > 0) {
          this.ex.express.user.avatar_files = this.$store.getters.GET_COMMON_AUTHORIZED?.user?.small_avatar_file
        }
        if(this.ex.express.user?.avatar_files && this.ex.express.user?.avatar_files.length > 0) {
          let avatarFile = this._.find(this.ex.express.user?.avatar_files, {type: 'small_image'})

          this.$store.dispatch('getFileFromServer', avatarFile['id'])
              .then((response) => {
                this.ex.express.user.userAvatar = {
                  id: avatarFile['id'],
                  base64: response[avatarFile.id],
                  mimeType: avatarFile['mime_type'],
                  uuid: avatarFile['uuid']
                }
                this.loadUserComplete = true
              }).catch(error => {
            this.createErrorLog(error)
            this.loadUserComplete = true
          })
        }
        else {
          this.loadUserComplete = true
        }
      },

      ExpressObjectString: function () {
        if (this.hasCreateLabel) {
          this.hasCreateLabel = false
        }
      }
    },

    computed: {
      getExpressStatusOption() {
        return this._.find(this.expressStatusOptions, {value: this.ex.express.expressStatus})
      },
      getExpressInvoiceStatusOption() {
        return this._.find(this.expressStatusInvoiceOptions, {value: this.ex.express.expressInvoiceStatus})
      },
      user() {
        return this.ex.express.user
      },
      ExpressObjectString() {
        let productsData = []
        this.ex.express.products.forEach(item => {
          if(this._.has(item.product?.proformData?.proformTypes[PROFORM_TYPE_NAMING]?.typeValue, 'value')){
            productsData.push({
              name: item.product.proformData.proformTypes[PROFORM_TYPE_NAMING].typeValue.value.translationStorage.en.name,
              count: item.product.itemsQuantity,
              price: item.product.price,
            })
          }
        })
        return JSON.stringify(this.ex.express.sendType) + JSON.stringify(this.ex.express.sendType.freightCost) + JSON.stringify(productsData) +
            JSON.stringify(this.ex.express.personal) + JSON.stringify(this.ex.express.delivery) + JSON.stringify(this.ex.express.packaging) +
            JSON.stringify(this.ex.express.senderContactName) + JSON.stringify(this.ex.express.senderPhone) + JSON.stringify(this.ex.express.warehouse)
      }
    },

    data() {
      return{
        progressStepsSetting: [
          this.$t('express_PikupInfo.localization_value.value'),
          this.$t('express_DeliveryDetails.localization_value.value'),
          this.$t('express_ProductsDetails.localization_value.value'),
          this.$t('express_Packaging.localization_value.value'),
          this.$t('ex_tabsConfirmation.localization_value.value'),
        ],

        TNT_DELIVERY_SERVICE: TNT_DELIVERY_SERVICE,
        DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
        FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
        NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE: NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,

        expressStatusOptions: [
          {name: 'Completed', value: EXPRESS_DELIVERY_STATUSES.COMPLETED_STATUS},
          {name: 'In Progress', value: EXPRESS_DELIVERY_STATUSES.IN_PROGRESS_STATUS},
          {name: 'Wait for pay', value: EXPRESS_DELIVERY_STATUSES.WAIT_FOR_PAY_STATUS},
          {name: 'Wait for making label', value: EXPRESS_DELIVERY_STATUSES.WAIT_FOR_MAKING_LABEL_STATUS},
        ],

        expressStatusInvoiceOptions: [
          {name: this.$t('express_statusInvoicePaid.localization_value.value'), value: EXPRESS_DELIVERY_STATUSES.COMPLETED_STATUS},
          {name: this.$t('express_statusInvoiceUnPaid.localization_value.value'), value: EXPRESS_DELIVERY_STATUSES.IN_PROGRESS_STATUS},
        ],

        activeStep: 1,

        serverError: false,

        isModalNoMoneyPopup: false,
        isModalCancelOrderCreation: false,
        isModalSaveInDraftsPopup: false,

        labelPrice: {},

        proformChanged: false,
        loadUserComplete: false,
        hasCreateLabel: false,
      }
    },

    methods:{

      changeExpressStatusOption(val) {
        this.ex.express.expressInvoiceStatus = val.value
        this.ex.express.changedAdminFields = true
      },

      changeExpressPayedInvoiceNetAmount(val) {
        this.ex.express.payedInvoiceNetAmount = val
        this.ex.express.changedAdminFields = true
      },

      changeExpressPayedInvoiceFeeAmount(val) {
        this.ex.express.payedInvoiceFeeAmount = val
        this.ex.express.changedAdminFields = true
      },

      showNoMoneyPopup() {
        this.isModalNoMoneyPopup = true
      },

      closeNoMoneyPopup() {
        this.isModalNoMoneyPopup= false
      },

      showCancelOrderCreation() {
        this.isModalCancelOrderCreation = true
      },

      closeCancelOrderCreation() {
        this.isModalCancelOrderCreation= false
      },

      showSaveInDraftsPopup() {
        this.isModalSaveInDraftsPopup = true
      },

      closeSaveInDraftsPopup() {
        this.isModalSaveInDraftsPopup = false
      },

      tryGetLabel() {
        if (!this.advancedUserValidate()) return

        this.getLabelPriceFromCard()
      },

      trySaveInDraft(edit) {
        if (!this.advancedUserValidate()) {
          this.closeSaveInDraftsPopup()
          return
        }

        this.saveInDraft(edit)
      }

    }
  }
</script>

<style scoped>

</style>
