<template>
  <modal
      @close="$emit('close')"
      class="what-order-create-modal what-order-create"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_ChooseOrderType'])"></div>
        {{$t('fbm_ChooseOrderType.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="what-order-create__list custom-row">
        <div class="what-order-create__item custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
             v-if="warehouse"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['easy_warehouse'])"></div>
          <router-link
              :to="$store.getters.GET_PATHS.easyOrderCreation + 'warehouse'"
              class="what-order-create__link"
          >
            <div class="what-order-create__ico">
              <OrderForConsolidationIco/>
            </div>
            {{$t('easy_warehouse.localization_value.value')}}
          </router-link>
        </div>
        <div class="what-order-create__item custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
             v-if="econom"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['easy_econom'])"></div>
          <router-link
              :to="$store.getters.GET_PATHS.easyOrderCreation + 'consolidation'"
              class="what-order-create__link"
          >
            <div class="what-order-create__ico">
              <OrderFromWarehouseIco/>
            </div>
            {{$t('easy_econom.localization_value.value')}}
          </router-link>
        </div>
        <div class="what-order-create__item custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
             v-if="express"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['easy_express'])"></div>
          <router-link
              :to="$store.getters.GET_PATHS.easyOrderCreation + 'fedex'"
              class="what-order-create__link"
          >
            <div class="what-order-create__ico">
              <CreateLabelIco/>
            </div>
            {{$t('easy_express.localization_value.value')}}
          </router-link>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../commonModals/Modal.vue'

  import CreateLabelIco from '../../../../../public/img/modal-group/create-label.svg?inline'
  import OrderFromWarehouseIco from '../../../../../public/img/modal-group/Order-from-Warehouse.svg?inline'
  import OrderForConsolidationIco from '../../../../../public/img/modal-group/Order-for-Consolidation.svg?inline'


  export default {
    name: "ChooseEasyOrderCreatePopup",

    components: {
      Modal,
      CreateLabelIco,
      OrderFromWarehouseIco,
      OrderForConsolidationIco,
    },

    props: {
      warehouse: {
        type: Boolean,
        default: true,
      },
      econom: {
        type: Boolean,
        default: true,
      },
      express: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {

      }
    },

  }

</script>

<style lang="scss">
  @import "../../../../scss/mixins/mixins";
  @import "../../../../scss/colors";


</style>
