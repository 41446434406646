<template>
  <div class="detail-cards">
    <div class="detail-cards__inner">
      <div class="detail-cards__content">
        <div class="detail-cards__section">
          <AccordBlock>
            <template slot="header">
              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_OrderDetails'])"></div>
                {{$t('fbm_OrderDetails.localization_value.value')}}
              </div>
            </template>
            <template slot="body">

              <SendFromBlock
                  :FBM="FBM"
                  :isAdminEdit="false"
              />

              <StepTwo
                  :FBM="FBM"
                  :proformProducts="FBM.data.ProformProducts"
                  :products="FBM.data.Products"
                  :productsForCellArray="FBM.data.productsForCellArray"
                  :user="FBM.data.User"
                  @changeCategory="({val, nameVal, item}) => changeCategoryProform({val, nameVal, item}, FBM.getTrsnAddressCountry(), true)"
                  @changeProformSelectItem="item => changeProformSelectItem(item, FBM.getTrsnAddressCountry())"
                  @addProformProduct="$emit('addProformProduct')"
                  @removeProformProduct="i => $emit('removeProformProduct', i)"
              />
            </template>
          </AccordBlock>

        </div>
        <div class="detail-cards__section">
          <AccordBlock>
            <template slot="header">
              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_ShippingPackage'])"></div>
                {{$t('fbm_ShippingPackage.localization_value.value')}}
              </div>
            </template>
            <template slot="body">
              <StepThree
                  :FBM="FBM"
                  :user="FBM.data.User"
                  :etsyCountry="FBM.data.DeliveryEtsy.delivery.country"
                  :paypalCountry="FBM.data.DeliveryPayPal.delivery.country"
              />

              <PriceBlock
                  ref="fbmExpertUserPriceBlock"
                  :FBM="FBM"
                  :loadingPrepayPrice="loadingPrepayPrice"
                  :prepayError="prepayError"
              />
            </template>
          </AccordBlock>
        </div>
        <div class="detail-cards__section detail-cards__section--btn">
          <div class="detail-cards__section-col">
            <router-link :to="$store.getters.GET_PATHS.easyOrders"
                         class="site-link site-link--alt"
                         v-if="isEasyType"
            >
              {{ $t('common_cancel.localization_value.value') }}
            </router-link>
            <router-link v-else :to="$store.getters.GET_PATHS.ordersFBM">
              <span class="site-link site-link--alt brown">
              {{$t('common_cancel.localization_value.value')}}
              </span>
            </router-link>
          </div>
          <div class="detail-cards__section-col">
            <MainButton
                class="order-create__footer-btn-i secondary"
                :value="$t('common_SaveInDrafts.localization_value.value')"
                :tooltip="true"
                v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBMBtn || loadingPrepayPrice}"
                @click.native="trySaveFBMAdvancedUser({draft: true})"
            >
              <template slot="tooltip">
                <p>
                  <b>{{$t('common_drafts.localization_value.value')}}</b>
                </p>
                <p>
                  {{$t('common_savingInDrafts.localization_value.value')}}
                </p>
              </template>
            </MainButton>

            <MainButton
                v-if="!isLabel && (isUserPrepayment && !gotPrepayment)"
                class="order-create__footer-btn-i"
                :value="$t('fbm_calculatePrepay.localization_value.value')"
                @click.native="getPrepay()"
                v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBMBtn || loadingPrepayPrice}"
            />

            <MainButton
                v-else-if="!isLabel && !prepayError"
                class="order-create__footer-btn-i"
                :value="$t('common_create.localization_value.value')"
                @click.native="trySaveFBMAdvancedUser({draft: false, adminManage: isAdmin})"
                v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBMBtn || loadingPrepayPrice}"
            />

            <MainButton
                v-else-if="!prepayError"
                class="order-create__footer-btn-i"
                :value="$t('common_buyLabel.localization_value.value')"
                @click.native="trySaveFBMAdvancedUser({draft: false, createLabel: true})"
                v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBMBtn}"
            />
          </div>
        </div>
      </div>
      <div class="detail-cards__aside">
        <div class="detail-cards__aside-inner">
          <OrderCard
              :FBM="FBM"
              :user="FBM.data.User"
              :personal="FBM.data.Personal"
              :deliveryFBM="FBM.data.DeliveryPayPal"
              @reload="reload"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  // import LinkButton from "../../../../../UI/buttons/LinkButton/LinkButton";
  import StepTwo from "../../components/FBMAdvancedUserBlocks/StepTwo/StepTwo";
  import StepThree from "../../components/FBMAdvancedUserBlocks/StepThree/StepThree";
  import {FBM_ORDER_TYPES} from "../../../../../../staticData/staticVariables";
  import {proformMixins} from "../../../../../../mixins/proformMixins/proformMixins";
  import {tabsMixins} from "../../../../../../mixins/creationPageMixins/tabsMixins";
  import {FBMMixins} from "../../../../../../mixins/FBMMixins/FBMMixins";
  // import TitleHorizontal from "@/components/coreComponents/TitleHorizontal/TitleHorizontal";
  // import DotsShadowPreloader from "@/components/coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
  // import LabelPrice from "@/components/coreComponents/LabelPrice/LabelPrice";
  import PriceBlock from "@/components/modules/OrdersFBMModule/components/components/chunks/PriceBlock/PriceBlock";
  import OrderCard from "../../components/FBMAdvancedUserBlocks/OrderCard/OrderCard";
  import AccordBlock from "../../../../../coreComponents/AccordBlock/AccordBlock";
  import SendFromBlock
    from "@/components/modules/OrdersFBMModule/components/components/chunks/SendFromBlock/SendFromBlock";

  export default {
    name: "OrdersFBMAdvancedCreationPage",
    components: {
      SendFromBlock,
      AccordBlock,
      PriceBlock,
      // LabelPrice,
      // DotsShadowPreloader,
      // TitleHorizontal,
      MainButton,
      StepTwo,
      StepThree,
      OrderCard
    },

    mixins: [proformMixins, tabsMixins, FBMMixins],

    props: {
      FBM: Object,
      advancedUI: Boolean,
    },

    computed: {
      FBMObjectString() {
        let proformJson = ''
        this.FBM.data.ProformProducts.forEach(p => {
          Object.keys(p.product.proformData.proformTypes).map(item => {
            if(p.product.proformData.proformTypes[item]?.typeValue) {
              proformJson += JSON.stringify(p.product.proformData.proformTypes[item].typeValue)
            }
          })
        })

        return JSON.stringify(this.FBM.data.Personal) + JSON.stringify(this.FBM.data.PersonalEtsy) + JSON.stringify(this.FBM.data.DeliveryPayPal) +
            JSON.stringify(this.FBM.data.DeliveryEtsy) + proformJson + JSON.stringify(this.FBM.data.productsForCellArray) +
            JSON.stringify(this.FBM.data.Dimensions) + JSON.stringify(this.FBM.data.insuranceAmount) + JSON.stringify(this.FBM.data.insuranceCost) +
            JSON.stringify(this.FBM.data.shippingPartner) + JSON.stringify(this.FBM.data.packagingType) + JSON.stringify(this.FBM.data.giftPackagingType) +
            JSON.stringify(this.FBM.data.giftPaper) + JSON.stringify(this.FBM.data.isInsurance) + JSON.stringify(this.FBM.data.signFromRecipient) +
            JSON.stringify(this.FBM.data.visibleVatNumber)
      }
    },

    watch: {
      FBMObjectString() {
        if (this.gotPrepayment) {
          this.gotPrepayment = false
        }
      }
    },

    data() {
      return {

        FBM_ORDER_TYPES: FBM_ORDER_TYPES,

        progressStepsSetting: [
          this.$t('fbm_CustomerInformation.localization_value.value'),
          this.$t('fbm_OrderDetails.localization_value.value'),
          this.$t('fbm_ShippingPackage.localization_value.value'),
          this.$t('fbm_Confirmation.localization_value.value'),
        ],

        activeStep: 1,

        isModalTransactionInfo: false,

        loadingPrepayPrice: false,
        prepayError: false,
        gotPrepayment: false,

        keyReload: -1,
      }
    },

    methods: {

      showTransactionInfo() {
        this.isModalTransactionInfo = true
      },

      closeTransactionInfo() {
        this.isModalTransactionInfo = false
      },

      reload() {
        this.keyReload = Math.random()
      },

      trySaveFBMAdvancedUser(args) {
        if (!this.expertUserValidate(false, true)) return
        this.$emit('prepareSaveFBM', args)
      },

      getPrepay() {
        if (!this.expertUserValidate(true)) return

        this.gotPrepayment = true
      },
    },


  }
</script>

<style scoped>

</style>
