<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fbm_id'])"></span>
        <DefaultInput
            :label="$t('fbm_id.localization_value.value')"
            :type="'text'"
            v-model="id"
        />
      </div>
    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import FilterBlock from "@/components/coreComponents/FilterBlock/FilterBlock";
import {filterFunc} from "@/mixins/filterFuncMixin/filterFunc";
import {usersFunc} from "@/mixins/usersMixins/usersFunc";
import {queryFilterMixin} from "@/mixins/routeFilterMixins/queryFilterMixin";
export default {
  name: "EasyOrdersTableFilter",
  components: {FilterBlock, DefaultInput, MainButton},

  mixins: [filterFunc, usersFunc, queryFilterMixin],

  props: {
    navTabs: Object,
    showFilter: Boolean,
    filterGetParams: Object,
  },

  data(){
    return {
      id: this.filterGetParams.id ? this.filterGetParams.id : '',

      filterCounts: [
        'id',
      ],

    }
  },

  watch: {
    filterGetParams(newVal) {
      this.id = newVal.id ? newVal.id : ''
    },
  },

  mounted() {

  },

  methods: {
    closeFilter() {
      this.$emit('close')
    },


    resetFilter() {
      let data = this.resetFilterParams(this, this.filterCounts)

      this.$emit('changeFilter', data)
    },

    changeFilter() {
      let data = this.changeFilterParams(this, this.filterCounts)

      this.$emit('changeFilter', data)
    },

  },



}
</script>

<style scoped>

</style>