<template>
  <div class="fragment">
    <EasyOrdersTableHead
        :showFilter="showFilter"
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeTab="(data) => $emit('changeTab', data)"
        @reload="$emit('reload')"
        @selectAll="selectAll"
        @thermalPrint="$emit('thermalPrint', {selected: true})"
    />
<!--        @generatePdf="$emit('generatePdf')"-->
<!--        @generateBarcodePdf="$emit('generateBarcodePdf')"-->
<!--        @royalMailManifest="$emit('royalMailManifest')"-->
<!--        @payFBM="$emit('payFBM')"-->
<!--        @getMailLabel="$emit('getMailLabel', {selected: true})"-->
    <div class="table-filter-wrap">

      <EasyOrdersTableFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          :navTabs="navTabs"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <EasyOrdersTableSectionTable
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          :selectedNow="selectedNow"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @deleteOrder="(item) => $emit('deleteOrder', item)"
          @thermalPrint="(data) => $emit('thermalPrint', data)"
      />
<!--          @copyOrder="(id) => $emit('copyOrder', id)"-->
<!--          @downloadFiles="type => $emit('downloadFiles', type)"-->
<!--          @getLabelFBM="val => $emit('getLabelFBM', val)"-->
<!--          @getBarcodeFBM="val => $emit('getBarcodeFBM', val)"-->
<!--          @reloadLabelFBM="val => $emit('reloadLabelFBM', val)"-->
<!--          @cancelPrepayOrder="id => $emit('cancelPrepayOrder', id)"-->
<!--          @paginate="(page) => {$emit('paginate', page)}"-->
<!--          @changePerPage="(perPage) => {$emit('changePerPage', perPage)}"-->
    </div>


  </div>
</template>

<script>
import EasyOrdersTableHead
  from "@/components/modules/EasyOrdersModule/components/EasyOrdersTable/EasyOrdersTableSection/EasyOrdersTableHead/EasyOrdersTableHead";
import EasyOrdersTableFilter
  from "@/components/modules/EasyOrdersModule/components/EasyOrdersTable/EasyOrdersTableSection/EasyOrdersTableFilter/EasyOrdersTableFilter";
import EasyOrdersTableSectionTable
  from "@/components/modules/EasyOrdersModule/components/EasyOrdersTable/EasyOrdersTableSection/EasyOrdersTableSectionTable/EasyOrdersTableSectionTable";
export default {
  name: "EasyOrdersTableSection",
  components: {EasyOrdersTableSectionTable, EasyOrdersTableFilter, EasyOrdersTableHead},

  props: {
    navTabs: Object,
    countFilterParams: Number,
    filterGetParams: Object,
  },

  data() {
    return {
      showFilter: false,
      selectedNow: false,
      isModalStatusOrderDetailsPopup: false
    }
  },

  methods: {
    closeFilter() {
      this.showFilter = false
    },

    toggleFilter() {
      this.showFilter = !this.showFilter
    },

    selectAll(val) {
      this.selectedNow = val
    },

  },

}
</script>

<style scoped>

</style>