<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
        'fbm_orderId',
        'fbm_PayPalTransactions'
      ])"></div>
      <CardLeftBlock
              :backgroundImage="'consolidation'"
              :name="$t('fbm_orderId.localization_value.value')"
              :value="'#'+FBM.data.Order.getId()"
      >
        <template slot="button">
          <MainButton
                  v-if="false"
                  :ico="true"
                  :value="$t('fbm_PayPalTransactions.localization_value.value')"
                  class="card-detail-left__btn"
                  @click.native="showTransactionInfo"
          >
            <template slot="ico">
              <PayPalIcon/>
            </template>
          </MainButton>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_backTo'])"></div>
                <router-link v-if="isEasyType" :to="$store.getters.GET_PATHS.easyOrders" class="order-create__head-back">
                  <LinkBack
                      :value="$t('common_backTo.localization_value.value')"
                  />
                </router-link>
                <router-link v-else :to="$store.getters.GET_PATHS.ordersFBM" class="order-create__head-back">
                  <LinkBack
                          :value="$t('common_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{
                  'color-lightBlue': isConsolidation,
                  'color-violet': isLabel,
                  'color-orange': isWarehouse,
                  'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()
                 }"
            >
              <div class="admin-edit" @click="editTranslate([
                'fbm_OrderForConsolidation',
                'fbm_OrderFromWarehouse',
                'fbm_CreateLabel'
              ])"></div>
              <template v-if="isConsolidation">
                <template v-if="isEasyType">{{$t('easy_econom.localization_value.value')}}</template>
                <template v-else>{{$t('fbm_OrderForConsolidation.localization_value.value')}}</template>
              </template>
              <template v-if="isLabel">
                {{$t('fbm_CreateLabel.localization_value.value')}}
              </template>
              <template v-if="isWarehouse">
                <template v-if="isEasyType">{{$t('easy_warehouse.localization_value.value')}}</template>
                <template v-else>{{$t('fbm_OrderFromWarehouse.localization_value.value')}}</template>
              </template>
            </div>
            <div class="order-create__head-txt"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fbm_PleaseFillOut'])"></div>
              {{$t('fbm_PleaseFillOut.localization_value.value')}}
            </div>

            <div v-if="!isAdmin"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                'fbm_CustomerInformation',
                'fbm_OrderDetails',
                'fbm_ShippingPackage',
                'fbm_Confirmation',
              ])"></div>
              <div class="order-create__head-steps">
                <ProgressSteps
                        :options="progressStepsSetting"
                        :step="activeStep"
                        @changeStep="stepChange"
                        @nextPageHandler="nextPageHandler"
                />
              </div>
            </div>
          </div>
        </template>

        <template slot="body">

          <div class="order-create__section">
            <div class="custom-row" v-if="isAdmin">
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                        :validationUser="FBM.data.User.validation.user"
                        :validationTxtUser="FBM.data.User.validationTxt.user"
                        :validationTranslateUser="FBM.data.User.validationTranslate.user"
                        :userSelected="FBM.data.User.user"
                        @changeUser="(user) => $emit('changeUser', user)"
                        :typeSelect="'users'"
                        :userEmail="true"
                />
              </div>
            </div>
          </div>

          <div class="order-create__section"
               v-if="activeStep == 1 || isAdmin">
            <StepOne
                    :FBM="FBM"
                    :personal="FBM.data.Personal"
                    :deliveryPayPal="FBM.data.DeliveryPayPal"
                    :deliveryEtsy="FBM.data.DeliveryEtsy"
                    :isEdit="true"
            />
          </div>
          <div class="order-create__section"
               v-if="activeStep == 2 || isAdmin">
              <StepTwo
                  :isEdit="true"
                      :FBM="FBM"
                      :proformProducts="FBM.data.ProformProducts"
                      :productsForCellArray="FBM.data.productsForCellArray"
                      :user="FBM.data.User"
                      @changeCategory="({val, nameVal, item}) => changeCategoryProform({val, nameVal, item}, FBM.getTrsnAddressCountry(), true)"
                      @changeProformSelectItem="item => changeProformSelectItem(item, FBM.getTrsnAddressCountry())"
                      @addProformProduct="$emit('addProformProduct')"
                      @removeProformProduct="i => $emit('removeProformProduct', i)"
              />

          </div>

          <div class="order-create__section" v-if="activeStep == 3 || isAdmin">
            <StepThree
                    :FBM="FBM"
                    :user="FBM.data.User"
                    :etsyCountry="FBM.data.DeliveryEtsy.delivery.country"
                    :paypalCountry="FBM.data.DeliveryPayPal.delivery.country"
            />
          </div>
          <div class="order-create__section" v-if="activeStep == 4 && !isAdmin">
            <StepFour
                    :FBM="FBM"
                    :personal="FBM.data.Personal"
                    :deliveryPayPal="FBM.data.DeliveryPayPal"
                    :deliveryEtsy="FBM.data.DeliveryEtsy"
                    :proformProducts="FBM.data.ProformProducts"
                    :products="FBM.data.Products"
                    @changeStep="step => {activeStep = step}"
            />


            <PriceBlock
                :FBM="FBM"
                :loadingPrepayPrice="loadingPrepayPrice"
                :prepayError="prepayError"
            />

<!--            <div class="order-create__title-horizontal"-->
<!--                 v-if="FBM.data.price > 0 || loadingPrepayPrice"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate([-->
<!--										'express_Shipping',-->
<!--									])"></div>-->
<!--              <TitleHorizontal-->
<!--                  :value="$t('express_Shipping.localization_value.value')"-->
<!--              />-->
<!--            </div>-->

<!--            <div class="pt-2 pb-3" v-if="loadingPrepayPrice">-->
<!--              <DotsShadowPreloader/>-->
<!--            </div>-->

<!--            <template v-if="FBM.data.price > 0 && !loadingPrepayPrice">-->
<!--              <LabelPrice-->
<!--                  :labelPrice="{price: FBM.data.price}"-->
<!--                  :bigCards="true"-->
<!--                  :dearCustomer="false"-->
<!--              />-->
<!--            </template>-->
          </div>
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_cancel', 'common_Previous'])"></div>
              <router-link :to="$store.getters.GET_PATHS.easyOrders"
                           class="site-link site-link--alt"
                           v-if="activeStep == 1 && isEasyType"
              >
                {{ $t('common_cancel.localization_value.value') }}
              </router-link>
              <router-link :to="$store.getters.GET_PATHS.ordersFBM"
                           class="site-link site-link--alt"
                           v-else-if="activeStep == 1"
              >
                {{ $t('common_cancel.localization_value.value') }}
              </router-link>
              <span class="site-link site-link--alt"
                    @click="prevPageHandler()"
                    v-if="activeStep != 1"
              >
                 {{ $t('common_Previous.localization_value.value') }}
              </span>
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_Next', 'fbm_saveBtn'])"></div>
              <MainButton
                      v-if="activeStep === progressStepsSetting.length && FBM.data.Order.data.isDraft"
                      class="order-create__footer-btn-i secondary"
                      :value="$t('common_SaveInDrafts.localization_value.value')"
                      :tooltip="true"
                      v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBMBtn || loadingPrepayPrice}"
                      @click.native="$emit('prepareSaveFBM', {draft: true, edit: true})"
              >
                <template slot="tooltip">
                  <p>
                    <b>{{$t('common_drafts.localization_value.value')}}</b>
                  </p>
                  <p>
                    {{$t('common_savingInDrafts.localization_value.value')}}
                  </p>
                </template>
              </MainButton>


              <MainButton
                      class="order-create__footer-btn-i"
                      :value="$t('common_Next.localization_value.value')"
                      @click.native="nextPageHandler()"
                      v-if="activeStep !== progressStepsSetting.length"
              />

              <MainButton
                      v-else-if="!isLabel && !prepayError"
                      class="order-create__footer-btn-i"
                      :value="$t('fbm_saveBtn.localization_value.value')"
                      @click.native="$emit('prepareSaveFBM', {draft: false, edit: true})"
                      v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBMBtn || loadingPrepayPrice}"
              />
              <MainButton
                      v-else-if="!prepayError"
                      class="order-create__footer-btn-i"
                      :value="$t('common_buyLabel.localization_value.value')"
                      @click.native="$emit('prepareSaveFBM', {draft: false, edit:true, createLabel: true})"
                      v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBMBtn}"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>
    </div>

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  // import LinkButton from "../../../../../UI/buttons/LinkButton/LinkButton";
  import ProgressSteps from "../../../../../UI/progress/ProgressSteps/ProgressSteps";
  import StepOne from "../../components/FBMUserBlocks/StepOne/StepOne";
  import StepTwo from "../../components/FBMUserBlocks/StepTwo/StepTwo";
  import StepThree from "../../components/FBMUserBlocks/StepThree/StepThree";
  import StepFour from "../../components/FBMUserBlocks/StepFour/StepFour";
  import PayPalIcon from "../../../../../../../public/img/company-icons-group/paypal-icon.svg?inline";
  import {FBM_ORDER_TYPES} from "../../../../../../staticData/staticVariables";
  import {proformMixins} from "../../../../../../mixins/proformMixins/proformMixins";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import {tabsMixins} from "../../../../../../mixins/creationPageMixins/tabsMixins";
  import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
  import {FBMMixins} from "../../../../../../mixins/FBMMixins/FBMMixins";
  // import TitleHorizontal from "@/components/coreComponents/TitleHorizontal/TitleHorizontal";
  // import DotsShadowPreloader from "@/components/coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
  // import LabelPrice from "@/components/coreComponents/LabelPrice/LabelPrice";
  import PriceBlock from "@/components/modules/OrdersFBMModule/components/components/chunks/PriceBlock/PriceBlock";

  export default {
    name: "OrdersFBMEditingPage",
    components: {
      PriceBlock,
      // LabelPrice,
      // DotsShadowPreloader,
      // TitleHorizontal,
      UserSelect,
      LinkBack,
      ProgressSteps,
      // LinkButton,
      CardRightBlock,
      MainButton,
      CardLeftBlock,
      StepOne,
      StepTwo,
      StepThree,
      StepFour,
      PayPalIcon,
    },

    mixins: [proformMixins, tabsMixins, FBMMixins],

    props: {
      FBM: Object,
    },



    data() {
      return {

        FBM_ORDER_TYPES: FBM_ORDER_TYPES,

        progressStepsSetting: [
          this.$t('fbm_CustomerInformation.localization_value.value'),
          this.$t('fbm_OrderDetails.localization_value.value'),
          this.$t('fbm_ShippingPackage.localization_value.value'),
          this.$t('fbm_Confirmation.localization_value.value'),
        ],

        activeStep: 1,

        isModalTransactionInfo: false,

        loadingPrepayPrice: false,
        prepayError: false,
      }
    },

    methods: {

      showTransactionInfo() {
        this.isModalTransactionInfo = true
      },

      closeTransactionInfo() {
        this.isModalTransactionInfo = false
      },

      // changeUser(user) {
      //   this.FBM.setUser(user)
      //   this.FBM.resetProduct()
      // },
    },


  }
</script>

<style scoped>

</style>
