import { render, staticRenderFns } from "./OrdersFBMEditingPage.vue?vue&type=template&id=2198bc5a&scoped=true"
import script from "./OrdersFBMEditingPage.vue?vue&type=script&lang=js"
export * from "./OrdersFBMEditingPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2198bc5a",
  null
  
)

export default component.exports