<template>
  <div class="fragment">
    <div class="order-create__section"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
      'fbm_ShippingPartner',
      'fbm_ShippingPartnerToltip'
    ])"></div>
<!--      <div class="order-create__section-label section-label">-->
<!--        {{$t('fbm_ShippingPartner.localization_value.value')}}-->
<!--        <v-popover-->
<!--            class="site-tooltip"-->
<!--            :disabled="!tooltipAddProductActive"-->
<!--            offset="5"-->
<!--            placement="top"-->
<!--            trigger="hover"-->

<!--        >-->

<!--          <TooltipBtn/>-->

<!--          <template slot="popover">-->
<!--            <p>-->
<!--              <b>{{$t('fbm_ShippingPartner.localization_value.value')}}</b>-->
<!--            </p>-->
<!--            <p>-->
<!--              {{$t('fbm_ShippingPartnerToltip.localization_value.value')}}-->
<!--            </p>-->
<!--          </template>-->
<!--        </v-popover>-->
<!--      </div>-->

<!--      <div class="order-create__title-horizontal"-->
<!--           v-if="isMobileFunc() && !isAdmin"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['fbm_ShippingCalculating'])"></div>-->
<!--        <div-->
<!--            @click="showShippingCalculatorPopup"-->
<!--        >-->
<!--          <TitleHorizontal-->
<!--              :value="$t('fbm_ShippingCalculating.localization_value.value')"-->
<!--              :typeBtn="'edit'"-->
<!--              @clickEdit="showShippingCalculatorPopup"-->
<!--          />-->
<!--        </div>-->

<!--      </div>-->

<!--      <FBMAdminFields-->
<!--          v-if="isAdmin"-->
<!--          :FBM="FBM"-->
<!--          :setInProgressStatus="true"-->
<!--      />-->

      <Shipping2
          :reloadKey="reloadKey"
          v-if="FBM.data.shippingCompanies.length > 0 && loadedShipping"
          :FBM="FBM"
          :isAdminManage="false"
          :shippingCompanyData="FBM.data.shippingCompanies"
          @showShippingCalculatorPopup="showShippingCalculatorPopup"
          @showSignatureInfoPopup="showSignatureInfoPopup"
      />

<!--      <div class="toggle-block__type-item mb-3"-->
<!--           v-if="isAdmin"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['fbm_ShippingCalculating'])"></div>-->
<!--        <span class="site-link" @click="getShippingData">-->
<!--        {{$t('fbm_ShippingCalculating.localization_value.value')}}-->
<!--      </span>-->
<!--      </div>-->


      <div class="row">
        <VatNumberBlock
            :FBM="FBM"
            :fileWidthClass="'custom-col--50'"
        />
      </div>


      <template v-if="!(globalCompany && isConsolidation)">
        <div class="order-create__section-label section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
        'fbm_Insurance',
        'fbm_InsuranceToltip'
      ])"></div>
          {{$t('fbm_Insurance.localization_value.value')}}
          <v-popover
              class="site-tooltip"
              :disabled="!tooltipAddProductActive"
              offset="5"
              placement="top"
              trigger="hover"
          >

            <TooltipBtn/>

            <template slot="popover">
              <p><b>{{$t('fbm_Insurance.localization_value.value')}}</b></p>
              <p>{{$t('fbm_InsuranceToltip.localization_value.value')}}</p>
            </template>
          </v-popover>
        </div>

        <InsuranceBlock
            :FBM="FBM"
            @changeInsurance="reload"
        />
      </template>


      <template
          v-if="!isLabel && !(isConsolidation && globalCompany) && !(isEasyType && isConsolidation)">
        <div class="order-create__section-label section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_Packaging'])"></div>
          {{$t('fbm_Packaging.localization_value.value')}}
        </div>

        <Packaging
            :FBM="FBM"
        />
      </template>


      <GiftMassage
          v-if="isWarehouse"
          :FBM="FBM"
          :giftPapers="giftPapers"
      />

      <div class="order-create__section-label section-label"
           v-if="isWarehouse"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_giftPackaging'])"></div>
        {{$t('fbm_giftPackaging.localization_value.value')}}
      </div>

      <GiftPackaging
          v-if="isWarehouse"
          :FBM="FBM"
      />



      <SignatureInfoPopup
          @close="closeSignatureInfoPopup"
          v-if="isModalSignatureInfoPopup"
      />


    </div>

    <ShippingCalculatorPopup
        @close="closeShippingCalculatorPopup"
        v-if="isModalShippingCalculatorPopup"
        :fromUSA="isWarehouse"
        :country="FBM.data.DeliveryPayPal.delivery.country"
        :dimensions="FBM.data.Dimensions"
        :city="FBM.data.DeliveryPayPal.delivery.city"
        :region="FBM.data.DeliveryPayPal.delivery.region"
        :zip="FBM.data.DeliveryPayPal.delivery.zip"
    />
  </div>

</template>

<script>


  import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import {VPopover} from "v-tooltip";
  // import TextareaDefault from "../../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import SignatureInfoPopup from "../../../../popups/SignatureInfoPopup/SignatureInfoPopup";
  import ShippingCalculatorPopup from "../../../../popups/ShippingCalculatorPopup/ShippingCalculatorPopup";
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  // import TitleHorizontal from "../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
  import Packaging from "./Packaging/Packaging";
  import Shipping2 from "../../chunks/Shipping2/Shipping2";
  import InsuranceBlock from "../../chunks/InsuranceBlock/InsuranceBlock";
  import GiftMassage from "./GiftMassage/GiftMassage";
  import {FBMMixins} from "../../../../../../../mixins/FBMMixins/FBMMixins";
  import {USA_COUNTRY_ID} from "../../../../../../../staticData/staticVariables";
  import VatNumberBlock from "../../chunks/VatNumberBlock/VatNumberBlock";
  import GiftPackaging from "./GiftPackaging/GiftPackaging";
  // import FBMAdminFields
  //   from "@/components/modules/OrdersFBMModule/components/components/FBMAdminEditBlocks/AdminFieldsBlock/FBMAdminFields/FBMAdminFields";


  export default {
    name: "StepThree",
    components: {
      GiftPackaging,
      // FBMAdminFields,
      VatNumberBlock,
      InsuranceBlock,
      Shipping2,
      Packaging,
      GiftMassage,
      TooltipBtn,
      VPopover,
      // TextareaDefault,
      SignatureInfoPopup,
      ShippingCalculatorPopup,
      // TitleHorizontal,
    },

    mixins: [mixinDetictingMobile, FBMMixins],

    props: {
      FBM: Object,
      user: Object,
      etsyCountry: [Object, String],
      paypalCountry: [Object, String],
    },

    watch: {
      /**
       * If load user and not admin or order type Edit
       */
      orderUser(newVal) {
        if(newVal !== '' && (!this.isAdmin || this.$route.params.id)){
          this.getShippingData().then(() => {
            this.getShippingPrices()
          })
        }
      },

      /**
       * If change or choose Country
       */
      selectedCountry() {
        this.getShippingData().then(() => {
          this.getShippingPrices()
        })
      },
    },

    computed: {
      orderUser(){
        return this.FBM.data.User.userId
      }
    },

    data(){
      return {
        USA_COUNTRY_ID: USA_COUNTRY_ID,

        options: [{}],

        tooltipAddProductActive: true,
        comment: 'Happy New Year! Wish you all best, by darling friend!',

        isModalSignatureInfoPopup: false,
        isModalShippingCalculatorPopup: false,


        radioVal: 0,
        shippingPartner: 1,


        giftPapers: [],

        reloadKey: -1,

        loadedShipping: false,
      }
    },

    mounted() {
      // this.$store.dispatch('getDeliveryTypeByCountry', this.FBM.getTrsnAddressCountry().id)

      console.log('step 3');
      this.checkShippingCompany().then(() => {
        this.loadedShipping = true
        this.getShippingPrices()
      })

      this.$store.dispatch('fetchPackagingGiftPapers', '?filter[enable]=1&page=0&limit=100000').then(response => {
        this.giftPapers = this.getRespPaginateData(response)
      })

    },

    methods: {
      reload() {
        this.reloadKey = Math.random()
        this.getShippingPrices()
      },

      showSignatureInfoPopup() {
        this.$store.dispatch('getSignaturePrice').then((response) => {
          console.log(this.getRespData(response));
          this.isModalSignatureInfoPopup= true
        })
      },

      closeSignatureInfoPopup() {
        this.isModalSignatureInfoPopup= false
      },

      showShippingCalculatorPopup() {
        this.isModalShippingCalculatorPopup= true
      },

      closeShippingCalculatorPopup() {
        this.isModalShippingCalculatorPopup= false
      },


      changeRadio(val){
        this.radioVal = val
      }
    },
  }
</script>

<style scoped>

</style>
