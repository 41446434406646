<template>
  <div class="fragment">
    <ExpressOrderEdit
        v-if="EO.data.Order.data.type === 'fedex'"
        :easyOrder="true"
    />

    <OrdersFBMEditing
        v-if="EO.data.Order.data.type === 'warehouse' || EO.data.Order.data.type === 'consolidation'"
        :easyOrder="true"
    />
  </div>
</template>

<script>
import OrdersFBMEditing from "@/components/modules/OrdersFBMModule/components/OrdersFBMEditing/OrdersFBMEditing";
import {EasyOrder} from "@/components/modules/EasyOrdersModule/models/EasyOrder";
import ExpressOrderEdit from "@/components/modules/ExpressOrdersModule/components/ExpressOrderEdit/ExpressOrderEdit";
export default {
  name: "EasyOrdersEdit",
  components: {
    ExpressOrderEdit,
    OrdersFBMEditing,
  },

  data() {
    return {
      EO: new EasyOrder(),
    }
  },

  mounted() {
    if(this.$route.params.type) {
      this.EO.data.Order.setType(this.$route.params.type)
    }
  },

}
</script>

<style scoped>

</style>