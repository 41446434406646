
export const vatNumberMixin = {
  methods: {
    getVatNumberPermissionsByCountry(country, isRussian = false){

      if(!country) return false
      if(country?.country_groups.length === 0) return false

      let fromHsCodeGroup = this._.find(country.country_groups, 'fromHsCodeGroup')
      console.log(123123);
      console.log(fromHsCodeGroup);
      console.log(isRussian);

      if(!fromHsCodeGroup) return false

      // if(fromHsCodeGroup && (this.isUkrainian || this.isPoland || this.isBelarus || this.isBaltic || this.isEAEUGroup || this.isAdmin || isRussian)){ //this.isBelarus ||
        return true
      // }

      // return false

    },

    async getExpressVatNumber(country, deliveryServiceId){

      if(!country) return false

      let data = {
        delivery_service_id: deliveryServiceId,
        recipient_country_id: country?.id
      }

      return this.$store.dispatch('getExpressNeedVatNumber', data).then(response => {
        if(this.getRespData(response)?.needVat) {
          return this.getRespData(response).needVat
        } else {
          return false
        }
      })

    },
  }
}
