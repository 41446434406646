
export const easyOrdersMixin = {

  methods: {

    getEasyOrderType(type) {
      switch (type) {
        case 'from_sklad':
          return 'warehouse'
        case 'express_fedex':
          return 'fedex'
        case 'econom':
          return 'consolidation'
      }
    },

  },

}