<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
        <DefaultCheckbox
            v-if="getEasyOrderType(item.order_type) !== 'fedex'"
            class="mt--1"
            :selectedNow="selectedNow"
            :dataValue="item.order_id"
        />

        <div class="table-card__item-number table-card__item-number--small">
<!--          <router-link-->
<!--              v-if="item.return_parcel && isAdmin"-->
<!--              :to="$store.getters.GET_PATHS.problemsReturnGoodsUrl + '/' + item.return_parcel.id"-->
<!--              class="d-flex justify-content-center">-->
<!--            <TooltipTextHelper>-->
<!--              <template slot="text">-->
<!--                <ReturnIconSVG class="mr-1"/>-->
<!--              </template>-->
<!--              <template slot="title">-->
<!--                      <span style="font-size: 14px; font-weight: bold">-->
<!--                        Id #{{item.return_parcel.id}}-->
<!--                      </span>-->
<!--              </template>-->
<!--            </TooltipTextHelper>-->
<!--          </router-link>-->


<!--                       :to="$store.getters.GET_PATHS.ordersFBM + '/show/' + getOrderFBMTypeById(item.order_type_id).name + '/' + item.id"-->
          <router-link :to="getShowLink"
                       class="d-flex justify-content-center">
            #{{item.order_id}}
          </router-link>
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('fbm_TrackingNumber.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  v-if="item.system_tracking && item.order_type === 'econom'"
                  :value="item.system_tracking"
              />
              <ValueHelper
                  v-else
                  :value="item.tracking_number"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('fbm_Created.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
        </div>


        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                class="mr-3 white-space-nowrap"
                v-if="item.status !== 'completed'"
                :value="item.in_draft ? $t('common_EditDraft.localization_value.value') : $t('common_edit.localization_value.value')"
                :type="'edit'"
                :link="getEditLink"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                v-if="item.status !== 'completed'"
                :value="$t('common_delete.localization_value.value')"
                :type="'delete'"
                @click.native="$emit('deleteOrder', item)"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t('common_termalPrint.localization_value.value')"
                :type="'print'"
                @click.native="$emit('thermalPrint', {id: [item.order_id]})"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import {easyOrdersMixin} from "@/mixins/easyOrdersMixins/easyOrdersMixin";
export default {
name: "EasyOrdersTableSectionTableMobile",
  components: {DefaultCheckbox, ValueHelper, LinkButton},

  mixins: [easyOrdersMixin],

  props: {
    item: Object,
    getEditLink: String,
    getShowLink: String,
  },


  data() {
    return {
      show: false,
    }
  },

}
</script>

<style scoped>

</style>