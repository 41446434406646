import {
  CAMBRIDGE_WAREHOUSE,
  FBM_FROM_TRANSACTIONS, FBM_IN_PROGRESS_STATUS,
  FBM_ORDER_TYPES,
  FBM_STATUSES, //LODZ_WAREHOUSE,
} from "../../../../staticData/staticVariables";
import {OrderFBM} from "./OrderFBM";
import _ from "lodash";
import moment from 'moment';

OrderFBM.prototype.prepareFBM = function (
  draft,
  user,
  isAdmin = false,
  adminManage = false,
  getCompany = false,
  edit = false,
  calcFee = false,
  calculatePrice = false,
  plannedDateWarehouse = false,
  plannedDateConsolidation = false,
) {
  let personal = this.data.transactionAddress !== this.data.transactionAddressTypes.etsy ?
      this.data.Personal : this.data.PersonalEtsy,
    delivery = this.data.transactionAddress !== this.data.transactionAddressTypes.etsy ?
      this.data.DeliveryPayPal : this.data.DeliveryEtsy,
    proformProducts = this.data.ProformProducts,
    products = this.data.productsForCellArray,
    dimensions = this.data.Dimensions,
    adminDimensions = this.data.AdminDimensions,
    userItem = this.data.User,
    order = this.data.Order,

    isHasSelectedProform = this.isHasSelectedProformProducts(),
    isHasSelectedProduct = this.isHasSelectedProducts(),

    /**
     * packing_type_id для админа передаю юзерский getPackagingType и админский getPackagingAdminPrice чтобы не потерять что выбрал юзер
     * а бэк подставит getPackagingAdminPrice для админа
     */
    data = {
      // "user_id": this.data.userId !== '' ? this.data.userId : user,
      "user_id": user ? user : userItem.getUserId(),
      "order_type_id" : order.getType()?.id,
      'storage_id': order.getWarehouse()?.id ? order.getWarehouse().id : null,
      "recipient_contact_name": personal.getPersonalName(),
      "recipient_phone_number" : personal.getPersonalPhone(),
      "recipient_company_name": personal.getPersonalCompany(),
      "recipient_email": personal.getPersonalEmail(),
      "recipient_address": delivery.getAddress(),
      "recipient_address_1": delivery.getAddress2(),
      "recipient_city": delivery.getCity(),
      "recipient_region": delivery.getRegion(),
      "recipient_country_id": delivery.getCountry()?.id,
      "recipient_zip_code": delivery.getZip(),
      "comment" : this.getComment(),
      // "delivery_method_id" : this.getShippingPartner()?.id,
      "packing_price_id" :
        adminManage ?
          this.getPackagingAdminPrice()?.id ?
            this.getPackagingAdminPrice()?.id : null :
          this.getPackagingType()?.user_packing_price ?
            this.getPackagingType().user_packing_price.id : null,
      "packing_type_id" : (adminManage && !this.getPackagingAdminType()) ? null: this.getPackagingType()?.id,
      "gift_packing": {
        "packing_type_id": adminManage ? this.getGiftPackagingAdminType()?.id || null : this.getGiftPackagingType()?.id || null,
        "packing_price_id": adminManage ? this.getGiftPackagingAdminPrice()?.id || null : this.getGiftPackagingPrice()?.id || null,
      },
      "lot_number" : this.getLotNumber(),
      "insurance_amount" : null,
      "gift_message" : this.getGiftMassage(),
      "gift_paper_id": this.getGiftPaper()?.id ? this.getGiftPaper().id : null,
      // "user_create_label" : {
      //   "shipping_company": "USPS",
      //   // "shipping_method": FirstClassMail,
      // },
      "in_draft": draft ? 1 : 0,
      // "require_signature": this.getSignFromRecipient() ? 1 : 0,

      "saturday_delivery": this.getPossibleSaturdayDelivery() ? 1 : 0,
    }

  if(this.getSignFromRecipient() && this.getShippingPartnerType()?.signature_price !== null) {
    data['require_signature'] = 1
  } else {
    data['require_signature'] = 0
  }

  if(this.data.isEngraving) {
    let engravings = []
    this.data.Engravings.map((engraving, engravingIndex) => {
      let dataEngraving = {
        "need_engraving": this.data.isEngraving ? 1 : 0,
        "length": Number(engraving.data.Dimensions.data.dimensionLength),
        "width": Number(engraving.data.Dimensions.data.width),
        "count": Number(engraving.data.count),
        "need_different_engrave": this.data.Engravings.length > 1 ? 1 : 0,
        "system_code": engravingIndex + '',
      }
      if(engraving.data.id) {
        dataEngraving['id'] = engraving.data.id
      }
      if(engraving.data.customization) {
        dataEngraving['customizations'] = {
          offer_id: engraving.data.customization?.id
        }
      }
      if(adminManage) {
        dataEngraving['complete'] = this.data.engravingAdminCheck ? 1 : 0
      }
      engravings.push(dataEngraving)
    })

    data['engravings'] = engravings
  } else {
    data['engraving'] = []
  }

  if(this.data.UVPrinting.data.isUVPrinting || Object.keys(this.data.UVPrinting.data.File.getDownloadFiles()).length > 0) {
    data['uv_print'] = {
      "need_print_uv": this.data.UVPrinting.data.isUVPrinting ? 1 : 0,
      "length": Number(this.data.UVPrinting.data.Dimensions.data.dimensionLength),
      "width": Number(this.data.UVPrinting.data.Dimensions.data.width),
      "quantity": Number(this.data.UVPrinting.data.count),
    }
    if(this.data.UVPrinting.data.customization) {
      data['uv_print']['customizations'] = {
        offer_id: this.data.UVPrinting.data.customization?.id
      }
    }
    if(adminManage) {
      data['uv_print']['complete'] = this.data.engravingAdminCheck ? 1 : 0
    }
  } else {
    data['uv_print'] = {'need_engraving': 0}
  }

  if(this.data.Embroidery.data.isEmbroidery || Object.keys(this.data.Embroidery.data.File.getDownloadFiles()).length > 0) {
    data['embroidery'] = {
      "need_embroidery": this.data.Embroidery.data.isEmbroidery ? 1 : 0,
      "number_of_stitches": Number(this.data.Embroidery.data.stitches),
      "quantity": Number(this.data.Embroidery.data.quantity),
    }
    if(this.data.Embroidery.data.customization) {
      data['embroidery']['customizations'] = {
        offer_id: this.data.Embroidery.data.customization?.id
      }
    }
    if(adminManage) {
      data['embroidery']['complete'] = this.data.engravingAdminCheck ? 1 : 0
    }
  } else {
    data['embroidery'] = {'need_embroidery': 0}
  }

  if(this.data.TShirtPrinting.data.isTShirtPrinting || Object.keys(this.data.TShirtPrinting.data.File.getDownloadFiles()).length > 0) {
    data['ts_painting'] = {
      "need_painting": this.data.TShirtPrinting.data.isTShirtPrinting ? 1 : 0,
      "quantity": Number(this.data.TShirtPrinting.data.quantity),
      // "color_type": this.data.TShirtPrinting.data.color,
      // "material_type": this.data.TShirtPrinting.data.quality,
    }
    if(this.data.TShirtPrinting.data.customization) {
      data['ts_painting']['customizations'] = {
        offer_id: this.data.TShirtPrinting.data.customization?.id,
        variantIds: this.data.TShirtPrinting.data.size ? [this.data.TShirtPrinting.data.size?.id] : null
      }
    }
    if(adminManage) {
      data['ts_painting']['complete'] = this.data.engravingAdminCheck ? 1 : 0
    }
  } else {
    data['ts_painting'] = {'need_painting': 0}
  }

  if(this.data.LeatherStamp.data.isLeatherStamp || Object.keys(this.data.LeatherStamp.data.File.getDownloadFiles()).length > 0) {
    data['leather_stamp'] = {
      "need_leather_stampings": this.data.LeatherStamp.data.isLeatherStamp ? 1 : 0,
      "stamping_line": this.data.LeatherStamp.data.stampingLine + '',
    }
    if(this.data.LeatherStamp.data.customization) {
      data['leather_stamp']['customizations'] = {
        offer_id: this.data.LeatherStamp.data.customization?.id,
      }
    }
    if(adminManage) {
      data['leather_stamp']['complete'] = this.data.engravingAdminCheck ? 1 : 0
    }
  } else {
    data['leather_stamp'] = {'need_leather_stampings': 0}
  }

  if(this.data.PhotoPrint.data.isPhotoPrint || Object.keys(this.data.PhotoPrint.data.File.getDownloadFiles()).length > 0) {
    data['photo_print'] = {
      "need_photo_print": this.data.PhotoPrint.data.isPhotoPrint ? 1 : 0,
      "quantity": Number(this.data.PhotoPrint.data.quantity),
    }
    if(this.data.PhotoPrint.data.customization) {
      data['photo_print']['customizations'] = {
        offer_id: this.data.PhotoPrint.data.customization?.id,
        variantIds: null
      }
    }
    if(this.data.PhotoPrint.data.size) {
      data['photo_print']['customizationsSize'] = {
        offer_id: this.data.PhotoPrint.data.size?.id,
        variantIds: null
      }
    }
    if(adminManage) {
      data['photo_print']['complete'] = this.data.engravingAdminCheck ? 1 : 0
    }
  } else {
    data['photo_print'] = {'need_photo_print': 0}
  }

  if(this.getIdReturned()) {
    data['return_parcel_id'] = parseInt(this.getIdReturned())
  }

  /**
   * Set order fee if autodetect in settings
   */
  if (calcFee && this.getCalcOrderProcessFee()) {
    data["order_fee_id"] = this.getCalcOrderProcessFee()?.id || null
  }

  /**
   * Set admin manage fields
   */

  if(adminManage){
    data["tracking_number"] = order.getTrackingNum()
    data["delivery_cost"] = this.getShippingCost()
    data["order_fee_id"] = this.getOrderPrice()?.id

    data['consolidation_order_union_id'] = this.getConsolidationOrderUnionId() ? this.getConsolidationOrderUnionId() : null

    data['consolidation_union_id'] = this.getConsolidationUnion()?.id ? this.getConsolidationUnion().id : null

    // set delivery date
    if(this.data.Order.getStatus()?.value === FBM_STATUSES.FBM_COMPLETED_STATUS.value ||
      this.data.useEasyPost && this.data.Order.getStatus()?.value === FBM_STATUSES.FBM_IN_PROGRESS_STATUS.value){
      data["delivery_date"] = moment(this.getReceivedAt()).format('YYYY-MM-DD')
    }

    if(plannedDateWarehouse && this.data.Order.getStatus()?.value !== FBM_STATUSES.FBM_COMPLETED_STATUS.value){
      data["delivery_date"] = moment(plannedDateWarehouse).format('YYYY-MM-DD')
    }
    if(plannedDateConsolidation  && this.data.Order.getStatus()?.value !== FBM_STATUSES.FBM_COMPLETED_STATUS.value){
      data["delivery_date"] = moment(plannedDateConsolidation).format('YYYY-MM-DD')
    }

    data["order_admin_fee"] = this.getSkladUsaFee()

    data["admin_packing_type_id"] = this.getPackagingAdminType()?.id
  }


  /**
   * Set insurance
   */
  if(this.getIsInsurance() && this.getInsuranceAmount() !== ''){
    data['insurance_amount'] = this.getInsuranceAmount()
  }

  if(getCompany){
    data['vat_tax'] = {
      "use_vat_id": this.data.visibleVatNumber && (//this.data.Order.data.warehouse?.id !== LODZ_WAREHOUSE.storage_id &&
        this.data.Order.data.warehouse?.id !== CAMBRIDGE_WAREHOUSE.storage_id) ? 1 : 0,
      "vat_tax": this.data.vatNumber,
      "file_base64": this.data.vatNumberFile.getFiles().length > 0 ?
        _.first(this.data.vatNumberFile.getFiles())['base64String'] : null
    }
    if(this.data.visibleVatNumber && this.data.IOSS?.id &&
      this.checkIsMarketplaceCompany(this.data.shippingPartner.id)
    ){
      data['order_ioss'] = {id: this.data.IOSS.id}
      data['vat_tax']['vat_tax'] = this.data.IOSS.number
    } else {
      data['order_ioss'] = null
    }

    if(this.data.shippingPartner?.need_paid_vat && !this.data.visibleVatNumber){
      data['addition_info'] = {
        info: {
          tax_clearance: {
            total_sum: this.data.transactionAmount,
            cost: this.data.customsClearance,
          }
        }
      }
    }

  }



  /**
   * Set ORDER DIMENSIONS
   */
  // if(this.data.Order.getType().id !== FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id || adminManage) {
  data['dimension'] = [
    {
      "weightKg": dimensions.getDimensionsWeightLB(),
      "weightGr": dimensions.getDimensionsWeightOZ(),
      "height": dimensions.getDimensionsHeight(),
      "width": dimensions.getDimensionsWidth(),
      "length": dimensions.getDimensionsLength()
    }
  ]
  if(adminManage){
    data['admin_dimension'] = [
      {
        "weightLb": adminDimensions.getDimensionsWeightLB(),
        "weightOz": adminDimensions.getDimensionsWeightOZ(),
        "height": adminDimensions.getDimensionsHeight(),
        "width": adminDimensions.getDimensionsWidth(),
        "length": adminDimensions.getDimensionsLength()
      }
    ]
    // }
    // else {
    //
    // }
  }

  data['dont_change_carrier'] = this.getChangeCarrier()

  /**
   * Set delivery company and method
   */
  if(this.getShippingPartnerType()?.delivery_company_id){
    data['delivery_company_id'] = this.getShippingPartnerType()?.delivery_company_id
  }

  if(this.getShippingPartner()?.id){
    data['delivery_method_id'] = this.getShippingPartner()?.id
  }

  if(this.getShippingAPIPrice()?.driver_delivery_method_id && !calculatePrice){
    data['rate'] = this.getShippingAPIPrice()
    if(this.getShippingAPIPrice()?.delivery_method_id && getCompany){
      data['delivery_method_id'] = this.getShippingAPIPrice()?.delivery_method_id
    }
  }


  /**
   * Set Products
   */
  if(this.getValidationProformProductsByCount(isHasSelectedProduct, isHasSelectedProform)) {
    /**
     * Proform Products Only For ORDER CONSOLIDATION & ORDER LABEL
     */
    if (this.data.Order.getType().id !== FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id) {
      let proform = []
      proformProducts.map(item => {
        // for consolidation add to proform NameForLabel
        if(this.data.Order.getType().id === FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.id && edit) {
          item.generateNameForLabel(adminManage)
        }
        proform.push(item.prepareSaveProform(true))
      })
      data['proform_data'] = proform
    }
  }

  if(this.getValidationProductsByCount(isHasSelectedProform, isHasSelectedProduct)) {
    /**
     * Products Only For ORDER CONSOLIDATION & ORDER WAREHOUSE
     */
    if (this.data.Order.getType().id !== FBM_ORDER_TYPES.FBM_ORDER_LABEL.id) {

      data["product_data"] = this.productsDataGeneration(products, isAdmin)

      // let productsData = []
      // products.map(product => {
      //   let item = product.data.Product
      //   productsData.push({
      //     "product_id": item.getProduct()?.id,
      //     "item_quantity": item.getProductCount(),
      //     "item_description": item.getProductDescription(),
      //     "item_price": parseFloat(item.getProductPrice()) > 0 ? parseFloat(item.getProductPrice()) : null,
      //     "id": item.getProductModelId(),
      //   })
      // })
      // data['product_data'] = productsData
    }
  } else {
    data["product_data"] = []
  }



  /**
   * Set transaction
   */
  if(order.getTransactionId() !== ''){
    if(order.getTransactionType() === FBM_FROM_TRANSACTIONS.PAYMENT.value){
      data['payment_transaction_id'] = parseInt(order.getTransactionId())
    }
    if(order.getTransactionType() === FBM_FROM_TRANSACTIONS.ORDER.value){
      data['order_external_id'] = parseInt(order.getTransactionId())
    }
  }


  /**
   * Set status
   */
  // if(isAdmin){
  if(!this.data.Order.getStatus()?.value){
    data['status'] = FBM_IN_PROGRESS_STATUS.value
  } else {
    data['status'] = this.data.Order.getStatus()?.value
  }
  // }

  // if(order.getTransactionType() === 'from_payment_transaction'){
  //
  // }


  if(edit) {
    data['deleted_proform_ids'] = {
      proform_data: this.data.proformProductsDeletedIds,
    }
    this.data.proformProductsDeletedIds = []
  }

  if(this.data.sendFrom) {
    data['send_from'] = {
      "first_name": this.data.sendFrom?.first_name,
      "last_name": this.data.sendFrom?.last_name,
      "company_name": this.data.sendFrom?.company_name
    }
  } else {
    data['send_from'] = null
  }


  data['customization_fee'] = this.data.isUserCustomizationFee ? 0 : null
  if(this.data.selectedCustomizationFee?.value) {
    if(this.data.selectedCustomizationFee.value && this.data.otherCustomizationFee) {
      data['customization_fee'] = this.data.otherCustomizationFee
    } else {
      data['customization_fee'] = this.data.selectedCustomizationFee.value
    }
  }

  data['user_tracking_number'] = this.data.userTrackingNumber

  return data
}

