<template>
  <div class="order-create__section"
       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
    <div class="admin-edit"
         @click="editTranslate(['fbm_ProductConsolidation', 'fbm_ProductConsolidationToltip'])"></div>

    <template v-if="!isWarehouse">
      <div class="order-create__section-label section-label">
        {{$t('fbm_ProductConsolidation.localization_value.value')}}
        <v-popover class="site-tooltip" :disabled="!tooltipAddProductActive" offset="5" placement="top" trigger="hover">
          <TooltipBtn/>
          <template slot="popover">
            <p><b>{{$t('fbm_ProductConsolidation.localization_value.value')}}</b></p>
            <p>{{$t('fbm_ProductConsolidationToltip.localization_value.value')}}</p>
          </template>
        </v-popover>
      </div>


      <div class="mb-2 color-orange-validation" v-if="FBM.validation.ProformProducts">
        {{ $t(`${FBM.validationTranslate.ProformProducts}.localization_value.value`) }}
      </div>
      <template v-for="(item, index) in proformProducts">
        <div class="order-create__title-horizontal"
             :key="index + '1'"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
						'product_Product',
						'common_DeleteProduct',
					])"></div>
          <TitleHorizontal
                  :value="$t('product_Product.localization_value.value') + ' #' + parseInt(index + 1)"
                  :rightBtn="true"
                  :rightBtnType="'delete'"
                  :rightBtnText="$t('common_DeleteProduct.localization_value.value')"
                  @rightBtnClick="$emit('removeProformProduct', index)"
          />
        </div>

        <ProductItemCreation
                :key="index"
                :product="item"
                :serverError="serverError"
                :visibleHSCode="FBM.data.visibleHSCode"
                :canChangeHSCode="true"
                :altVersion="true"
                @changeCategory="({val, nameVal}) => $emit('changeCategory', {val, nameVal, item: proformProducts[index]})"
                @changeProformSelectItem="$emit('changeProformSelectItem', item)"
        />
      </template>

      <div class="mb-4"
           v-if="isEasyType && isConsolidation">
        <MainButton
            class="wfc ml-auto"
            :value="'+ ' + $t('common_AddProduct.localization_value.value')"
            @click.native="$emit('addProformProduct')"
        />
      </div>

      <div class="order-create__product-btn add-product-btn-wrap"
           v-else
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_AddProduct'])"></div>
        <span class="order-create__product-btn-i add-product-btn site-link"
              @click="$emit('addProformProduct')">
          + {{$t('common_AddProduct.localization_value.value')}}
        </span>
      </div>

      <div class="order-create__total mb-3"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_TotalShippingCost',])"></div>
        {{$t('common_TotalShippingCost.localization_value.value')}}
        <span>${{calculatePrice}}</span>
      </div>
    </template>


    <template
            v-if="!isLabel && !(isEasyType && isConsolidation)"
    >
      <div class="order-create__section-label section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_ProductWarehouse',])"></div>
        {{$t('fbm_ProductWarehouse.localization_value.value')}}
      </div>

      <template v-if="showProducts">
        <div class="custom-row">
          <div class="custom-col">
            <div class="d-flex">
              <RadioDefault
                  class="white-space-line width-initial mr-3"
                  v-for="(item, index) in warehouses"
                  :key="index"
                  :label="item.name"
                  :name="'warehouse'"
                  :value="FBM.data.Order.data.warehouse && item.id === FBM.data.Order.data.warehouse.id"
                  @input="changeWarehouse(item)"
              />
            </div>
          </div>
        </div>

        <div class="mb-2 color-orange-validation" v-if="FBM.validation.productsForCellArray">
          {{ $t(`${FBM.validationTranslate.productsForCellArray}.localization_value.value`) }}
        </div>

        <div class="fragment"
             v-if="initialOptionsProducts.length > 0"
             v-bind:class="{'loading-products': productLoading}"
        >
          <SelectProductBlock
              v-for="(item, index) in productsForCellArray"
              :autoloadSelect="true"
              :initialOptionsProducts="initialOptionsProducts"
              :key="index+'product'"
              :item="item.data.Product"
              :userId="parseInt(user.userId)"
              :productLabel="'name_for_fbm'"
              :price="!checkSelectedUSACountry"
              :description="!checkSelectedUSACountry"
              :hsCode="!checkSelectedUSACountry"
              :disabled="isDisabledCustomization(item.data.Product) || isUseCustomizationAndEdit"
              :disabledAllFields="isDisabledCustomization(item.data.Product) || isUseCustomizationAndEdit"
              @removeProduct="removeProduct(productsForCellArray, index)"
              @changeProductName="changeProductName"
              @changeProductQuantity="changeProductQuantity"
            />
        </div>

        <div class="order-create__product-btn add-product-btn-wrap"
             v-bind:class="{
              'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
              'disabled-btn': isUseCustomizationAndEdit,
            }"
        >
          <div class="admin-edit" @click="editTranslate(['common_AddProduct',])"></div>
          <span class="order-create__product-btn-i add-product-btn site-link"
                @click="FBM.addProductForCells()">
            + {{$t('common_AddProduct.localization_value.value')}}
            </span>
        </div>
      </template>
      <template v-else>
        <div class="mb-4">
          <MainButton
              class="wfc"
              :value="'+ ' + $t('common_AddProduct.localization_value.value')"
              @click.native="showOrAddProduct()"
          />
        </div>
      </template>
    </template>



    <div class="order-create__section-label section-label"
         v-if="!isWarehouse"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_Dimentions'])"></div>
      {{$t('fbm_Dimentions.localization_value.value')}}
    </div>

    <div class="custom-row mt-auto" v-if="isEasyType && isConsolidation">
      <div class="custom-col custom-col--66 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_DimentionRestrictions', 'fbm_DimentionRestrictionsTxt'])"></div>
        <WarningStatic class="mw-100 w-100">
          <template slot="title">
            {{$t('fbm_DimentionRestrictions.localization_value.value')}}
          </template>
          <template slot="text">
            <span v-html="$t('fbm_DimentionRestrictionsTxt.localization_value.value')"></span>
          </template>
        </WarningStatic>
      </div>
    </div>

    <Dimensions
            :FBM="FBM"
            :dimensions="FBM.data.Dimensions"
    />


    <EngravingFile
        v-if="isWarehouse && !isWarehouseCambridge"
      :FBM="FBM"
    />


    <!--<LotNumberBlock-->
            <!--:FBM="FBM"-->
    <!--/>-->




<!--    <AddToProfilePopup-->
<!--            @close="closeAddToProfilePopup"-->
<!--            v-if="isModalAddToProfilePopup"-->
<!--    />-->

<!--    <DeleteProductPopup-->
<!--            @close="closeDeleteProductPopup"-->
<!--            v-if="isModalDeleteProductPopup"-->
<!--    />-->

  </div>
</template>

<script>
  // import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  // import AddToProfileIco from '../../../../../../../../public/img/UI-group/add-to-profile-ico.svg?inline'
  import TitleHorizontal from "../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
  // import InputQuantity from "../../../../../../UI/inputs/InputQuantity/InputQuantity";
  // import InputSum from "../../../../../../UI/inputs/InputSum/InputSum";
  import {VPopover} from "v-tooltip";
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  // import DeleteProductBtn from "../../../../../../coreComponents/DeleteProductBtn/DeleteProductBtn";
  // import AddToProfilePopup from "../../../../popups/AddToProfilePopup/AddToProfilePopup";
  // import DeleteProductPopup from "../../../../popups/_DeleteProductPopup/DeleteProductPopup";
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import SelectProductBlock from "../../../../../../coreComponents/SelectProductBlock/SelectProductBlock";
  import {CAMBRIDGE_WAREHOUSE, FBM_ORDER_TYPES} from "../../../../../../../staticData/staticVariables";
  import {FBMMixinsHelper, FBMProductMixin} from "../../../../../../../mixins/FBMMixins/FBMMixins";
  import Dimensions from "../../chunks/Dimensions/Dimensions";
  import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";
  import ProductItemCreation from "../../../../../../coreComponents/ProductItemCreation/ProductItemCreation";
  import EngravingFile
    from "@/components/modules/OrdersFBMModule/components/components/chunks/EngravingFile/EngravingFile";
  import WarningStatic from "@/components/UI/alerts/WarningStatic/WarningStatic";
  // import LotNumberBlock from "../../chunks/LotNumberBlock/LotNumberBlock";

  export default {
    name: "StepTwo",

    components: {
      WarningStatic,
      ProductItemCreation,
      EngravingFile,
      MainButton,
      RadioDefault,
      // LotNumberBlock,
      Dimensions,
      SelectProductBlock,
      // DefaultSelect,
      TitleHorizontal,
      // AddToProfileIco,
      // InputQuantity,
      // InputSum,
      VPopover,
      // DefaultCheckbox,
      TooltipBtn,
      // DeleteProductBtn,
      // AddToProfilePopup,
      // DeleteProductPopup,
    },

    mixins: [mixinDetictingMobile, FBMMixinsHelper, FBMProductMixin],

    props: {
      FBM: Object,
      user: Object,
      proformProducts: Array,
      productsForCellArray: Array,
      isEdit: {
        type: Boolean,
        default: true
      },
    },


    computed: {},

    data() {
      return {
        options: [{}],

        FBM_ORDER_TYPES: FBM_ORDER_TYPES,

        tooltipAddProductActive: true,

        serverError: false,

        initialOptionsProducts: [],
        productLoading: false,

        warehouses: [],
        showProducts: false,

        CAMBRIDGE_WAREHOUSE: CAMBRIDGE_WAREHOUSE,
      }
    },

    mounted() {
      this.getWarehouseStorage()
      this.checkHideProductsFromWarehouse()
    },

    methods: {



      changeValueQuantity(item, val) {
        // console.log(data);
        item.setProductCount(val)
      },


      changeValueSum(data) {
        this.valueSum = data
      },

      showOrAddProduct() {
        if (this.productsForCellArray.length === 0) {
          this.FBM.addProductForCells()
        }
        this.showProducts = true
      },

      checkHideProductsFromWarehouse() {
        if ((this.productsForCellArray.length > 0 && this.productsForCellArray[0]?.data?.Product?.product?.id && !this.showProducts) || !this.isConsolidation) {
          this.showProducts = true
        }
      }

    },
  }
</script>

<style scoped>
.width-initial{
  width: initial;
}
.loading-products{
  opacity: 0.5;
  pointer-events: none;
}
</style>
