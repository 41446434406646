<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_PersonalInformation'])"></div>
      {{$t('fbm_PersonalInformation.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_ContactName'])"></div>
        <DefaultInput
                :label="$t('fbm_ContactName.localization_value.value')"
                :type="'text'"
                :name="'name'"
                :autocomplete="'name'"
                v-bind:class="{'ui-no-valid': personal.validation.personalName}"
                :errorTxt="$t(`${personal.validationTranslate.personalName}.localization_value.value`)"
                :error="personal.validation.personalName"
                v-model="personal.information.personalName"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_PhoneNumber'])"></div>
        <DefaultInput
                :label="$t('fbm_PhoneNumber.localization_value.value')"
                :type="'tel'"
                :autocomplete="'tel'"
                v-bind:class="{'ui-no-valid': personal.validation.personalPhone}"
                :errorTxt="$t(`${personal.validationTranslate.personalPhone}.localization_value.value`)"
                :error="personal.validation.personalPhone"
                v-model="personal.information.personalPhone"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_CompanyName'])"></div>
        <DefaultInput
                :label="$t('fbm_CompanyName.localization_value.value')"
                :type="'text'"
                :name="'company'"
                :autocomplete="'organization'"
                v-bind:class="{'ui-no-valid': personal.validation.personalCompany}"
                :errorTxt="$t(`${personal.validationTranslate.personalCompany}.localization_value.value`)"
                :error="personal.validation.personalCompany"
                v-model="personal.information.personalCompany"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_Email'])"></div>
        <DefaultInput
                :label="$t('fbm_Email.localization_value.value')"
                :type="'email'"
                :name="'email'"
                :autocomplete="'email'"
                v-bind:class="{'ui-no-valid': personal.validation.personalEmail}"
                :errorTxt="$t(`${personal.validationTranslate.personalEmail}.localization_value.value`)"
                :error="personal.validation.personalEmail"
                v-model="personal.information.personalEmail"
        />
      </div>

      <div v-if="isEasyType && isConsolidation"
           class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_userTrackNumber'])"></div>
        <DefaultInput
            :label="$t('fbm_userTrackNumber.localization_value.value')"
            v-model="FBM.data.userTrackingNumber"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {FBMMixinsHelper} from "@/mixins/FBMMixins/FBMMixins";
  export default {
    name: "PersonalInfoBlock",
    components: {DefaultInput},

    mixins: [FBMMixinsHelper],

    props: {
      personal: Object,
      FBM: Object,
    }
  }
</script>

<style scoped>

</style>
