import {checkValid} from "@/components/globalModels/functions/ModelsValidate";
import {Order} from "@/components/globalModels/Order";


export function EasyOrder() {

  this.checkValid = checkValid

  this.data = {
    Order: new Order(),
  }

}