<template>
  <div class="order-create__section">

    <SendFromBlock
        :FBM="FBM"
        :isAdminEdit="false"
        :isEdit="isEdit"
    />

    <PersonalInfoBlock
            :personal="FBM.data.transactionAddress === FBM.data.transactionAddressTypes.etsy ? personalEtsy : personal"
            :FBM="FBM"
    />

    <div v-if="$store.getters.GET_COMMON_AUTHORIZED && $store.getters.GET_COMMON_AUTHORIZED.user && $store.getters.GET_COMMON_AUTHORIZED.user.email
    && $store.getters.GET_COMMON_AUTHORIZED.user.email === 'admin@expressposhta.com'" class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_expressTrack'])"></div>
        <DefaultInput
            :label="$t('fbm_expressTrack.localization_value.value')"
            :type="'text'"
            :name="'expressTrack'"
            v-model="FBM.data.expressTrack"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_npTrack'])"></div>
        <DefaultInput
            :label="$t('fbm_npTrack.localization_value.value')"
            :type="'text'"
            :name="'npTrack'"
            v-model="FBM.data.npTrack"
        />
      </div>
    </div>

    <template v-if="FBM.data.transactionAddress === FBM.data.transactionAddressTypes.none">
      <div class="order-create__section-label section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_Address'])"></div>
        {{$t('fbm_Address.localization_value.value')}}
      </div>

      <div class="order-create__section-sub"
           v-bind:class="{'inactive-block': FBM.data.transactionAddress === FBM.data.transactionAddressTypes.etsy}"
      >
        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_Country', 'express_ViewCustomLimits'])"></div>
            <DefaultSelect
                :options="options"
                :label="$t('fbm_Country.localization_value.value')"
                :optionsLabel="'label'"
                @change="val => {changeCountry(val, 'paypal')}"
                v-bind:class="{'ui-no-valid': deliveryPayPal.validation.country}"
                :errorTxt="serverError ? deliveryPayPal.validationTxt.country : $t(`${deliveryPayPal.validationTranslate.country}.localization_value.value`)"
                :error="deliveryPayPal.validation.country"
                :selected="deliveryPayPal.delivery.country"
            />

            <div class="site-link site-link-alt mt-3 ml-auto wfc	d-flex"
                 v-if="FBM.data.DeliveryPayPal.delivery.country !== ''"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                        'express_ViewCustomLimits',
                      ])"></div>
              <div
                  @click="showCustomLimitsPopup(FBM.getTrsnAddressCountry().id, 'isModalCustomLimitsPopup')"
              >
                {{$t('express_ViewCustomLimits.localization_value.value')}}
              </div>
            </div>
          </div>
        </div>
        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_Address', 'express_maxSymbols'])"></div>
            <DefaultInput
                    :label="$t('fbm_Address.localization_value.value')"
                    :type="'text'"
                    :name="'address'"
                    :autocomplete="'address-line1'"
                    v-bind:class="{'ui-no-valid': deliveryPayPal.validation.address}"
                    :errorTxt="serverError ? deliveryPayPal.validationTxt.address : $t(`${deliveryPayPal.validationTranslate.address}.localization_value.value`)"
                    :error="deliveryPayPal.validation.address"
                    :maxLength="'35'"
                    :caption="$t('express_maxSymbols.localization_value.value')"
                    v-model="deliveryPayPal.delivery.address"
            />
          </div>
          <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_addressSecond', 'express_maxSymbols'])"></div>
            <DefaultInput
                    :label="$t('common_addressSecond.localization_value.value')"
                    :type="'text'"
                    :name="'address2'"
                    :autocomplete="'address-line2'"
                    v-bind:class="{'ui-no-valid': deliveryPayPal.validation.address2}"
                    :errorTxt="$t(`${deliveryPayPal.validationTranslate.address2}.localization_value.value`)"
                    :error="deliveryPayPal.validation.address2"
                    :maxLength="'35'"
                    :caption="$t('express_maxSymbols.localization_value.value')"
                    v-model="deliveryPayPal.delivery.address2"
            />
          </div>
          <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_City'])"></div>
            <DefaultInput
                    :label="$t('fbm_City.localization_value.value')"
                    :type="'text'"
                    :name="'city'"
                    :autocomplete="'address-level2'"
                    v-bind:class="{'ui-no-valid': deliveryPayPal.validation.city}"
                    :errorTxt="serverError ? deliveryPayPal.validationTxt.city : $t(`${deliveryPayPal.validationTranslate.city}.localization_value.value`)"
                    :error="deliveryPayPal.validation.city"
                    v-model="deliveryPayPal.delivery.city"
            />
          </div>
          <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_Region'])"></div>
            <DefaultSelect
                v-if="deliveryPayPal.delivery.country && (deliveryPayPal.delivery.country.id === USA_COUNTRY_ID || deliveryPayPal.delivery.country.id === AUSTRALIA_ID ||
                deliveryPayPal.delivery.country.id === CANADA_COUNTRY_ID || deliveryPayPal.delivery.country.id === MEXICO_COUNTRY_ID) && FBM.getRegions().length > 0"
                :options="FBM.getRegions()"
                :label="$t('fbm_Region.localization_value.value')"
                v-bind:class="{'ui-no-valid': deliveryPayPal.validation.region}"
                :errorTxt="$t(`${deliveryPayPal.validationTranslate.region}.localization_value.value`)"
                :error="deliveryPayPal.validation.region"
                :optionsLabel="'code'"
                :selected="getSelectedRegion()"
                @change="val => {changeRegion(val)}"
            />
            <DefaultInput
                v-else
                    :label="$t('fbm_Region.localization_value.value')"
                    :type="'text'"
                    :name="'region'"
                    :autocomplete="'address-level1'"
                    v-bind:class="{'ui-no-valid': deliveryPayPal.validation.region}"
                    :errorTxt="serverError ? deliveryPayPal.validationTxt.region : $t(`${deliveryPayPal.validationTranslate.region}.localization_value.value`)"
                    :error="deliveryPayPal.validation.region"
                    v-model="deliveryPayPal.delivery.region"
            />
          </div>
          <div class="order-create__col custom-col custom-col--16  custom-col--md-25 custom-col--sm-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_ZipCode'])"></div>
            <DefaultInput
                :label="$t('fbm_ZipCode.localization_value.value')"
                :type="'text'"
                :name="'zip'"
                :autocomplete="'postal-code'"
                v-bind:class="{'ui-no-valid': deliveryPayPal.validation.zip}"
                :errorTxt="serverError ? deliveryPayPal.validationTxt.zip : $t(`${deliveryPayPal.validationTranslate.zip}.localization_value.value`)"
                :error="deliveryPayPal.validation.zip"
                v-model="deliveryPayPal.delivery.zip"
            />
          </div>

        </div>
      </div>
    </template>

    <div class="order-create__section-sub"
         v-if="FBM.data.transactionAddress !== FBM.data.transactionAddressTypes.none"
    >
      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_WeReceivedTwoAddresses'])"></div>
          <div class="order-create__txt mw540">
            {{$t('fbm_WeReceivedTwoAddresses.localization_value.value')}}
          </div>
        </div>


        <div class="order-create__col custom-col">
          <div class="order-create__toggle-block toggle-block">
            <div class="toggle-block__type">
              <div class="toggle-block__type-list">
                <div class="toggle-block__type-item mb-3"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_TransactionAddress'])"></div>
                  <RadioDefault
                          :label="$t('fbm_TransactionAddress.localization_value.value')"
                          name="'addressType'"
                          :value="FBM.data.transactionAddress === FBM.data.transactionAddressTypes.paypal"
                          @change.native="FBM.data.transactionAddress = FBM.data.transactionAddressTypes.paypal"
                  >
                  </RadioDefault>
                </div>
                <div class="toggle-block__type-item mb-3"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_EtsyAddress'])"></div>
                  <RadioDefault
                          :label="$t('fbm_EtsyAddress.localization_value.value')"
                          name="'addressType'"
                          :value="FBM.data.transactionAddress === FBM.data.transactionAddressTypes.etsy"
                          @change.native="FBM.data.transactionAddress = FBM.data.transactionAddressTypes.etsy"
                  >
                  </RadioDefault>
                </div>
              </div>
            </div>


            <AddressBlock
                    :options="options"
                    :delivery="FBM.data.transactionAddress === this.FBM.data.transactionAddressTypes.etsy ? deliveryEtsy : deliveryPayPal"
                    :deliveryPayPal="deliveryPayPal"
                    :deliveryEtsy="deliveryEtsy"
                    :FBM="FBM"
                    :translationTitle="FBM.data.transactionAddress === this.FBM.data.transactionAddressTypes.paypal ? 'fbm_TransactionAddress' : 'fbm_EtsyAddress'"
                    @showCustomLimitsPopup="showCustomLimitsPopup(FBM.getTrsnAddressCountry().id, 'isModalCustomLimitsPopup')"
                    @changeCountry="changeCountry"
                    @changeRegion="changeRegion"
            />

          </div>
        </div>
      </div>
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_CommentOptional', 'fbm_PleaseVaseInside'])"></div>
        <TextareaDefault
                :label="$t('fbm_CommentOptional.localization_value.value')"
                :placeholder="$t('fbm_PleaseVaseInside.localization_value.value')"
                v-model="FBM.data.comment"
        />
      </div>
    </div>


    <CustomLimitsPopup
            @close="closeCustomLimitsPopup"
            v-if="isModalCustomLimitsPopup"
    />


  </div>
</template>

<script>
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import TextareaDefault from "../../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import RadioDefault from "../../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import {filterCountriesUSA} from "../../../../../../../services/filterCountries";
  import {customLimitsMixin} from "../../../../../../../mixins/commonMixins/customLimitsMixin";
  import CustomLimitsPopup from "../../../../../../coreComponents/Popups/CustomLimitsPopup/CustomLimitsPopup";
  import AddressBlock from "../../chunks/AddressBlock/AddressBlock";
  import PersonalInfoBlock from "../../chunks/PersonalInfoBlock/PersonalInfoBlock";
  import {USA_COUNTRY_ID, AUSTRALIA_ID, CANADA_COUNTRY_ID, MEXICO_COUNTRY_ID} from "../../../../../../../staticData/staticVariables";
  import SendFromBlock
    from "@/components/modules/OrdersFBMModule/components/components/chunks/SendFromBlock/SendFromBlock";

  export default {
    name: "StepOne",

    components: {
      SendFromBlock,
      PersonalInfoBlock,
      AddressBlock,
      DefaultInput,
      DefaultSelect,
      TextareaDefault,
      CustomLimitsPopup,
      RadioDefault,
    },

    mixins: [customLimitsMixin],

    props: {
      FBM: Object,
      personal: Object,
      personalEtsy: Object,
      deliveryPayPal: Object,
      deliveryEtsy: Object,
      serverError: {
        type: Boolean,
        default: false,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
    },

    // computed: {
    //   getTransactionType() {
    //     return this.FBM.data.transactionAddress === this.FBM.data.transactionAddressTypes.etsy ? 'etsy' : 'paypal'
    //   },
    // },



    data() {
      return {
        options: [{}],

        name: 'Kate Washington',
        phone: '+1 384 481 38 90',
        companyName: '',
        address: 'Bryklin str, 9 ofice 293-A',
        city: 'Minneapolis',
        country: '',
        region: '',
        code: '',
        zip: '25778',
        comment: 'Please be careful with the vase inside! ',
        date: null,

        USA_COUNTRY_ID: USA_COUNTRY_ID,
        AUSTRALIA_ID: AUSTRALIA_ID,
        CANADA_COUNTRY_ID: CANADA_COUNTRY_ID,
        MEXICO_COUNTRY_ID: MEXICO_COUNTRY_ID,

        isModalCustomLimitsPopup: false,
      }
    },

    mounted() {
      let url = ''


      if (this.$route.query.idReturned) {
        url = '?return_parcel_id=' + this.$route.query.idReturned;
      }

      this.$store.dispatch('fetchCountries', url).then(() => {
        let countries = this._.toArray(this.$store.getters.getCountries)
        filterCountriesUSA(countries)
        this.options = countries;
      })
    },

    methods: {
      changeCountry(val) {
        if (this.FBM.data.transactionAddress === this.FBM.data.transactionAddressTypes.etsy) {
          this.FBM.data.DeliveryEtsy.setCountry(val)
        }

        if (this.FBM.data.transactionAddress !== this.FBM.data.transactionAddressTypes.etsy) {
          this.FBM.data.DeliveryPayPal.setCountry(val)
        }

        this.FBM.setShippingPartnerType({})
        this.FBM.setShippingPartner({})
        this.changeRegion('')
      },

      changeRegion(val) {
        if (this.FBM.data.transactionAddress === this.FBM.data.transactionAddressTypes.etsy) {
          this.FBM.data.DeliveryEtsy.setRegion(val?.code ? val?.code : '')
        }

        if (this.FBM.data.transactionAddress !== this.FBM.data.transactionAddressTypes.etsy) {
          this.FBM.data.DeliveryPayPal.setRegion(val?.code ? val?.code : '')
        }

      },

      getSelectedRegion() {
        return this.FBM.getRegions().find(item => {
          return item?.code.toLowerCase() === this.FBM.data.DeliveryPayPal.getRegion().toLowerCase()
        })
      },


      closeCustomLimitsPopup() {
        this.isModalCustomLimitsPopup = false
      },


      addressTypeeChange(index) {
        console.log(index);
      }
    },
  }
</script>

<style scoped>

  .inactive-block {
    opacity: 0.5;
    pointer-events: none;
  }

</style>
